import React, { useEffect, useLayoutEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { useAuthState } from '../../Helper/Context';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { BaseUrl } from '../../Helper/BaseUrl';
import { useTranslation } from 'react-i18next';


export default function Sidebar(props) {

    const { active, listActive, reportsActive, ProjectTypeId, Language, YearValue, YearLabel, MonthValue, MonthLabel, WardValue, WardLabel, ZoneValue, ZoneLabel } = props
    const { t } = useTranslation()

    // console.log("Languageewe", Language)

    const userDetails = useAuthState();
    const { UserID, token, M_ProjectTypeID, RoleID } = userDetails
    const dispatch = useDispatch()

    // console.log('ProjectTypeId', ProjectTypeId)

    const Pathname = window.location.pathname
    const [searchParams] = useSearchParams()
    // let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    // console.log(DashboardName)

    const [MasterProjectTypeID, setMasterProjectTypeID] = useState(ProjectTypeId)

    const navigate = useNavigate()

    // const toggle = () => {
    //     const sidebar = document.getElementById('SidebarToggle')
    //     // console.log(sidebar)
    //     sidebar.classList.toggle('active')
    // }

    const onMouseOver = () => {
        const sidebar = document.getElementById('SidebarToggle')
        const sidebaractive = sidebar.classList.contains("sidebaractive")
        if (!sidebaractive) {
            sidebar.classList.remove('active')
        }
    }

    const onMouseOut = () => {
        const sidebar = document.getElementById('SidebarToggle')
        const sidebaractive = sidebar.classList.contains("sidebaractive")
        if (!sidebaractive) {
            sidebar.classList.add('active')
        }
    }

    return (
        // <div>
        <nav className="pcoded-navbar sidebar mob_side active" id="SidebarToggle"
            // onMouseOver={() => toggle()}
            // onMouseOut={() => toggle()}
            onMouseOver={() => onMouseOver()}
            onMouseOut={() => onMouseOut()}
        >

            <div className="navbar-wrapper  ">
                <div className="navbar-content scroll-div ">
                    <ul className="nav pcoded-inner-navbar pt-5 pt-lg-2 mt-lg-4">
                        <li className="nav-item pcoded-menu-caption dashIcon">
                            <div style={{ cursor: 'pointer' }}>

                                {
                                    (RoleID == '1' || RoleID == '4' || RoleID == '5' || RoleID == '6') ?
                                        <div className={`${active === "garden" ? "active nav-item" : ""}`} style={{ cursor: 'pointer' }}
                                            onClick={() => navigate(`/garden?ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&RoleID=${RoleID}`)}>
                                            <span className='d-flex align-items-center' >
                                                <img src={'assets/img/home.png'} style={{ width: "24px" }} />
                                                <span className='ml-2 poppins sidebarName' style={{ fontSize: '15px', color: '#eaedf0' }}> {t('Sidebar.Dashboard')}</span>
                                            </span>
                                        </div>
                                        :
                                        <div className={`${active === "officialdashboard" ? "active nav-item" : ""}`}
                                            onClick={() => navigate(`/officialdashboard?DashboardName=${DashboardName}`)} style={{ cursor: 'pointer' }}>
                                            <span className='d-flex align-items-center' >
                                                <img src={'assets/img/home.png'} style={{ width: "24px" }} />
                                                <span className='ml-2 poppins sidebarName' style={{ fontSize: '15px', color: '#eaedf0' }}> {t('Sidebar.Dashboard')}</span>
                                            </span>
                                        </div>
                                }

                                {/* -------------------Master----------------- */}

                                {
                                    RoleID == '1' &&
                                    <>
                                        <div className={`nav-item pcoded-menu-caption dashIcon pt-4${listActive == "masters" ? "active" : ""}`} style={{ cursor: "pointer" }} data-bs-toggle="collapse" data-bs-target="#masters" aria-expanded="false" aria-controls="masters">
                                            <span className='d-flex align-items-center' >
                                                <img src={'assets/img/buildingmaster.png'} style={{ width: "22px" }} />
                                                <span className='ml-2 poppins sidebarName' style={{ fontSize: '15px', color: 'white' }}>{t('AdminDashboard.Admin_Sidebar_Name.Master')}</span>
                                            </span>
                                        </div>

                                        <div className={`sidebarName ${listActive === "masters" ? "collapse show" : "collapse"}`} id="masters">


                                            <div className="card card-body ps-4 pe-4 colScroll" style={{ padding: '7px', paddingLeft: '11.5px', overflowY: "scroll", height: "280px" }}> {/*//height: "400px"*/}

                                                <li className={` nav-item my-1 toBlock ${active === "department" ? "active" : ""}`}>
                                                    <div className={` nav-item  toBlock ${active === "department" ? "active" : ""}`}>
                                                        <li className={`${active === "designation" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/designation?ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)}>
                                                                <span  >
                                                                    {t('AdminDashboard.Admin_Sidebar_Name.Designation')}
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li className={` ${active === "employee" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/employee?ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('AdminDashboard.Admin_Sidebar_Name.Employee')}
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li className={` ${active === "usercreation" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/usercreation?ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('AdminDashboard.Admin_Sidebar_Name.User_Creation')}
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li className={` ${active === "contractorname" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/contractorname?ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('Dashboard.Work_Assigned_by_Officials.Contractor_Name')}
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li className={`${active === "zonemaster" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/zonemaster?ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('AdminDashboard.Admin_Sidebar_Table_Heading.Zone_Name')}
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li className={`${active === "wardmaster" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/wardmaster?ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('AdminDashboard.Admin_Sidebar_Name.Ward_Master')}
                                                                </span>
                                                            </span>
                                                        </li>

                                                        {/* {
                                                            BaseUrl === "http://pcmcapi.cdatinfotech.com" || BaseUrl === "http://pcmcdemo.cdatinfotech.com" ?

                                                                <> */}
                                                        {/* <li className={`${active === "sectormaster" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/sectormaster?ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    Sector Master
                                                                </span>
                                                            </span>
                                                        </li> */}
                                                        <li className={`${active === "worktype" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/worktype?ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('Sidebar.Work_Type')}
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li className={`${active === "worksubtype" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/worksubtype?ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('AdminDashboard.Admin_Sidebar_Name.Work_Sub_Type')}
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li className={`${active === "work" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/work?ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('AdminDashboard.Admin_Sidebar_Name.Work')}
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li className={`${active === "projectname" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/projectname?ProjectTypeFlag=Graden&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('AdminDashboard.Admin_Sidebar_Name.Project_Name')}
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li className={`${active === "projectwisecontractor" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/projectwisecontractor?ProjectTypeFlag=Graden&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('AdminDashboard.Admin_Sidebar_Name.Project_Wise_Contractor')}
                                                                </span>
                                                            </span>
                                                        </li>
                                                        {/* <Link to='/gardenwisemaintenancestatus'><li>Garden wise maintenance status </li></Link> */}
                                                        <li className={`${active === "contractorwiseassets" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/contractorwiseassets?ProjectTypeFlag=Graden&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('AdminDashboard.Admin_Sidebar_Name.Contractor_Wise_Assets')}
                                                                </span>
                                                            </span>
                                                        </li>

                                                        {/* <li className={`${active === "projectwiseworkname" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/projectwiseworkname?ProjectTypeFlag=Graden&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('AdminDashboard.Admin_Sidebar_Name.Project_Wise_Work_Name')}
                                                                </span>
                                                            </span>
                                                        </li> */}
                                                        <li className={`${active === "gardenwisemaintenancestatus" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/gardenwisemaintenancestatus?ProjectTypeFlag=Graden&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('AdminDashboard.Admin_Sidebar_Name.Garden_wise_maintenance_status')}
                                                                </span>
                                                            </span>
                                                        </li>

                                                        <li className={`${active === "userwisewardmapping" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                            <span
                                                                onClick={() => navigate(`/userwisewardmapping?ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}>
                                                                <span >
                                                                    {t('AdminDashboard.Admin_Sidebar_Name.User_Wise_Ward_Mapping')}
                                                                </span>
                                                            </span>
                                                        </li>

                                                        {/* <Link to='/projectname'><li>Project Name</li></Link>
                                                        <Link to='/projectwisecontractor'><li>Project Wise Contractor</li></Link> */}
                                                    </div>
                                                </li>
                                            </div>

                                        </div>
                                    </>
                                }
                                {
                                   (RoleID == 2 || RoleID == 3 || RoleID == 7 || RoleID == 15) &&
                                    <div className={`${active === "garden" ? "active nav-item" : ""}`} style={{ cursor: 'pointer' }}>

                                        <div className={`nav-item pcoded-menu-caption dashIcon pt-4${listActive === "officialmaster" ? "active" : ""}`} style={{ cursor: "pointer" }} data-bs-toggle="collapse" data-bs-target="#officialmaster" aria-expanded="false" aria-controls="collapseExample">
                                            <span className='d-flex align-items-center' >
                                                <img src={'assets/img/plant.png'} style={{ width: "24px" }} />
                                                <span className='ml-2 poppins sidebarName' style={{ fontSize: '15px', color: '#eaedf0' }}>{t('AdminDashboard.Admin_Sidebar_Name.Master')}</span>
                                            </span>
                                        </div>
                                        <div className={`sidebarName ${listActive === "officialmaster" ? "collapse show" : "collapse"}`} id="officialmaster" >
                                            <div className="card card-body ps-4 pe-4 colScroll mb-0" style={{ padding: "7px", overflowY: "scroll", height: "30vh" }}>
                                                <div className='nav-item'>
                                                    <li className={`${active === "gardenmaster" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                        <span
                                                            onClick={() => window.open(`/gardenmaster?Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)}>
                                                            <span >
                                                                {t('Dashboard.Pcmc_Assets.Garden_Master')}
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li className={`${active === "roaddivider" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                        <span
                                                            onClick={() => window.open(`/roaddivider?Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)}>
                                                            <span >
                                                                {t('Dashboard.Pcmc_Assets.Road_Divider')}
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li className={`${active === "openspace" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                        <span
                                                            onClick={() => window.open(`/openspace?Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)}>
                                                            <span >
                                                                {t('Dashboard.Pcmc_Assets.Open_Space_master')}
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li className={`${active === "treebelt" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                        <span
                                                            onClick={() => window.open(`/treebelt?Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)}>
                                                            <span >
                                                                {t('Dashboard.Pcmc_Assets.Tree_Belt’s_master')}
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li className={`${active === "chowks" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                        <span
                                                            onClick={() => window.open(`/chowks?Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)}>
                                                            <span >
                                                                {t('Dashboard.Pcmc_Assets.chowks_master')}
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li className={`${active === "playequipment" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                        <span
                                                            onClick={() => window.open(`/playequipment?Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)}>
                                                            <span >
                                                                {t('Dashboard.Pcmc_Assets.Play_Equipments')}
                                                            </span>
                                                        </span>
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    (RoleID == '1' || RoleID == '3') ?

                                        <div className={`${(active == "garden" || active == "officialdashboard") ? "active nav-item" : ""}`} style={{ cursor: 'pointer' }}>
                                            <>
                                                <div className={`nav-item pcoded-menu-caption dashIcon pt-4${listActive === "gardenReport" ? "active" : ""}`} style={{ cursor: "pointer" }} data-bs-toggle="collapse" data-bs-target="#gardenReport" aria-expanded="false" aria-controls="collapseExample">
                                                    <span className='d-flex align-items-center' >
                                                        <img src={'assets/img/report.png'} style={{ width: "24px" }} />
                                                        <span className='ml-2 poppins sidebarName' style={{ fontSize: '15px', color: 'white' }}>{t('Sidebar.Garden_Report')}</span>
                                                    </span>
                                                </div>

                                                <div className={`sidebarName ${listActive === "gardenReport" ? "collapse show" : "collapse"}`} id="gardenReport" >
                                                    <div className="card card-body ps-4 pe-2 colScroll" style={{ padding: "7px", overflowY: "scroll", height: "20vh" }}>
                                                        <div className='nav-item'>
                                                            <li className={`${active === "preventiveWorkReport" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                                <span
                                                                    // onClick={() => navigate(`/preventiveWorkReport?ProjectTypeFlag=Graden&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}
                                                                    onClick={() => window.open(`/preventiveWorkReport?ProjectTypeFlag=Graden&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}
                                                                >
                                                                    <span >
                                                                        {t('Sidebar.Preventive_Work_Report')}
                                                                    </span>
                                                                </span>
                                                            </li>
                                                            <li className={`${active === "completedWorkReport" ? "active" : ""}`} style={{ cursor: 'pointer' }}>
                                                                <span
                                                                    // onClick={() => navigate(`/completedWorkReport?ProjectTypeFlag=Graden&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}
                                                                    onClick={() => window.open(`/completedWorkReport?ProjectTypeFlag=Graden&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)}
                                                                >
                                                                    <span >
                                                                        {t('Sidebar.Completed_Work_Report')}
                                                                    </span>
                                                                </span>
                                                            </li>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                        :
                                        ''
                                }
                                {
                                    RoleID == '3' &&
                                    <div className={`${active === "dailyworksheettable" ? "active nav-item" : ""}`}
                                        // onClick={() => navigate(`/dailyworksheettable?DashboardName=${DashboardName}&Lang=${Language.Label}`)}
                                        onClick={() => window.open(`/dailyworksheettable?DashboardName=${DashboardName}&Lang=${Language.Label}`)}
                                    >
                                        <span className='d-flex align-items-center mt-4' >
                                            <img src={'assets/img/biometric-attendance.png'} style={{ width: "24px" }} />
                                            <span className='ml-2 poppins sidebarName' style={{ fontSize: '15px', color: '#eaedf0' }}>Daily Attendance Sheet</span>
                                        </span>
                                    </div>
                                }
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>



    )
}

