import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { WorkTypeDDL } from '../../../../../Components/CommonDDL/WorkTypeDDL'
import EmployeeAndContractorWorkPopup from './CountPopup/TotalCountPopup'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment";
import { CommonOfficerWorkStatusTableDataAPI } from '../../../../../Redux/CommDashbaord/OfficerWorkStatus/CommonOfficerWorkStatusSlice'
import { Pegination } from '../../../../../Components/Pegination/Pegination'
import { EmployeeAndContractPieChart } from './EmployeeAndContractPieChart'
import { ExportEmployeeAndContractor } from './ExportEmployeeAndContractor'
import { Loading } from '../../../../../Helper/Loading'

export const EmployeeAndContractorWisePendingWorkCard = (props) => {

    const { ActiveButton, ZoneValue, WardValue, Language, WorkActiveButton } = props
    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch()

    // console.log("ZoneValue", ZoneValue)
    // console.log("WardValue", WardValue)

    const { t } = useTranslation()

    const [EmployeeAndContractorWorkPopupShow, setEmployeeAndContractorWorkPopupShow] = useState({
        show: false,
        data: '',
        ShowBy: ''
    })

    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(9999)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [rowNo, setrowNo] = useState(1)

    // const [FromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'))
    // const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))

    const [FromDate, setFromDate] = useState('')
    const [ToDate, setToDate] = useState('')

    const handleEmployeeAndContractorWorkPopup = (data, ShowBy) => {
        setEmployeeAndContractorWorkPopupShow({
            show: true,
            data: data,
            ShowBy: ShowBy
            // flag: flag
        })
    }


    const handleOnCloseClick = () => {
        setEmployeeAndContractorWorkPopupShow({
            show: false,
            data: '',
            // flag: flag
        })
    }

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        // ID: ZoneNameDDLID.DDL[0].value,
        // Label: ZoneNameDDLID.DDL[0].label,
        ID: 0,
        Label: "Select...",
    })
    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    // const [Language, setLanguage] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: ''
    // })

    // useEffect(() => {
    //     setLanguage({
    //         ...Language,
    //         DDL: [],
    //         ID: 0,
    //         Label: localStorage.getItem('LanguageChange'),
    //     })
    // }, [])
    // const [ActiveButton, setActiveButton] = useState('ठेकेदार');

    const [GraphActiveButton, setGraphActiveButton] = useState(t('EmployeeAndContractor.Total'));

    // const handleButtonClick = (index) => {
    //     setActiveButton(index);
    // };

    const handleGraphButtonClick = (value) => {
        setGraphActiveButton(value);
    };

    const [ShowGraph, setShowGraph] = useState(false)

    const handleClear = () => {
        setFrom(1)
        setTo(10)
        setCurrentPage(0)
        setIsClear(!IsClear)
        setFromDate('')
        setToDate('')
        setWorkType({
            ...WorkType,
            ID: 0,
            Label: 'Select...',
        })
        // setZoneNameDDLID({
        //     ...ZoneNameDDLID,
        //     ID: ZoneNameDDLID.DDL[0].value,
        //     Label: ZoneNameDDLID.DDL[0].label,
        // })
        setWardName({
            ...WardName,
            ID: 0,
            Label: 'Select...'
        })
    }
    useEffect(() => {
        const data = {
            FromDate: FromDate,
            ToDate: ToDate,
            M_WorkTypeID: WorkActiveButton,
            // M_ZoneID: ZoneNameDDLID.ID,
            // M_WardID: WardName.ID,
            // M_ZoneID: ZoneValue,
            M_ZoneID: 0,
            M_WardID: WardValue,
            M_UserID: UserID,
            token: token,
            From: From,
            To: To,
            Flag: ActiveButton == 'कर्मचारी' ? 'Officer' : ActiveButton == 'ठेकेदार' ? 'Contractor' : 'ReopenContractor',
            Language: Language.Label,
        }
        { (Language.Label != '') && dispatch(CommonOfficerWorkStatusTableDataAPI({ data })) }
    }, [IsPost, FromDate, ToDate, IsClear, To, WardValue, WardValue, ZoneNameDDLID.ID, WardName.ID, WorkActiveButton, ActiveButton, Language.Label])

    const { tableData, isLoading } = useSelector(state => state.CommonOfficerWorkStatusTableData)

    // useEffect(() => {
    //     const data = {
    //         M_Table_UserID: '0',
    //         M_RoleID: NewRoleDDL.ID,
    //         M_DesignationID: '0',
    //         M_EmployeeID: EmployeeDDL.ID,
    //         M_UsersID: UserID,
    //         token: token,
    //         From: From,
    //         To: '99999',
    //         M_DepartmentID: NewDepartment.ID,
    //         M_ZoneID: ZoneNameDDLID.ID,
    //         M_WardID: WardName.ID,
    //         M_ContractorID: ContractorName.ID
    //     }
    //     dispatch(UserCreationExportTableDataAPI({ data }))
    // }, [IsPost, IsClear, To, NewRoleDDL.ID, EmployeeDDL.ID, NewDepartment.ID, ZoneNameDDLID.ID, WardName.ID, ContractorName.ID])

    // const { ExportTableData, isExportLoading } = useSelector(state => state.UserCreationExportTableData)

    const Receiveddata = {
        labels: ["Completed", "Pending", "XYZ"],
        datasets: [
            {
                label: '# of Votes',
                data: [15, 38, 47],
                backgroundColor: [
                    "#ffc33d",
                    "#9c27b0",
                    "#44ce42"
                ],
                borderColor: [
                    // '#28c8c1',
                    // '#172b4d',
                    // '#993366',
                    // '#593e89',
                    // '#94408e',
                    // '#c74382',
                    // '#fa5d00',
                    // '#529ad2',
                    // '#ffc137',
                ],
                borderWidth: 1,
            },
        ],
    };

    const ButtonOptions = [
        {
            label: t('EmployeeAndContractor.Total'),
            value: t('EmployeeAndContractor.Total')
        },
        {
            label: t('EmployeeAndContractor.0_to_7_days'),
            value: t('EmployeeAndContractor.0_to_7_days')
        },
        {
            label: t('EmployeeAndContractor.7_to_15_days'),
            value: t('EmployeeAndContractor.7_to_15_days')
        },
        {
            label: t('EmployeeAndContractor.15_to_30_days'),
            value: t('EmployeeAndContractor.15_to_30_days')
        },
        {
            label: t('EmployeeAndContractor.30_to_90_days'),
            value: t('EmployeeAndContractor.30_to_90_days')
        },
        {
            label: t('EmployeeAndContractor.90_to_180_days'),
            value: t('EmployeeAndContractor.90_to_180_days')
        },
        {
            label: t('EmployeeAndContractor.1_year'),
            value: t('EmployeeAndContractor.1_year')
        }
    ];

    return (
        <div className='p-2'>
            {isLoading && <Loading />}
            {/* {isDeleteLoading && <Loading />} */}
            {/* <div className='row'> */}
            {/* <div className='col-lg-5'>
                    <p className='ps-2 pt-2 pt-1 fw-bold' style={{ fontSize: "20px" }}>
                        {
                            ActiveButton == 'कर्मचारी' ?
                                <>
                                    {t('EmployeeAndContractor.Officer_Name_Pending_Work_Status')}
                                </>
                                :
                                <>
                                    {t('EmployeeAndContractor.Contractor_Name_Pending_Work_Status')}
                                </>
                        }
                    </p>
                </div> */}
            {/* <div className='col-lg-3 pt-1'>
                    <div className="btn-group text-center" role="group" aria-label="Basic outlined example" style={{ border: '1px solid #154779', borderRadius: '5px', }}>
                        <button
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => handleButtonClick('कर्मचारी')}
                            className={ActiveButton == 'कर्मचारी' ? "btn fw-bold px-4 activebutton text-white d-flex" : "text-black fw-bold btn px-4 d-flex hovernone"}
                        >{t('EmployeeAndContractor.Karmachari')}</button>
                        <button
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => handleButtonClick('ठेकेदार')}
                            className={ActiveButton == 'ठेकेदार' ? "btn px-4 fw-bold activebutton text-white d-flex" : "text-black fw-bold btn px-4 d-flex hovernone"}
                            type="button">{t('EmployeeAndContractor.Thekedar')}</button>
                    </div> 
                </div> */}
            {/* <div className='col-lg-2 pt-1'>
                    <button type="button" className="btn btn-primary float-end" style={{ background: 'blue' }}
                        onClick={() => setShowGraph(!ShowGraph)}
                    >{ShowGraph ? 'Hide Graph' : 'Show Graph'}</button>
                </div>
                <div className='col-lg-2 pt-1'>
                    <button type="button" className="btn btn-secondary">PDF</button> 
                    <ExportEmployeeAndContractor
                        tableData={tableData}
                        name={ActiveButton == 'कर्मचारी' ? t('EmployeeAndContractor.Officer_Name_Pending_Work_Status') : t('EmployeeAndContractor.Contractor_Name_Pending_Work_Status')}
                        ActiveButton={ActiveButton}
                        FromDate={FromDate}
                        ToDate={ToDate}
                        WardName={WardName && WardName?.Label}
                        ZoneNameDDLID={ZoneNameDDLID && ZoneNameDDLID?.Label}
                        WorkType={WorkType && WorkType?.Label}
                    />
                </div> */}
            {/* </div> */}
            {/* <hr className='fw-bold m-1 mb-2' style={{ height: '2px solid black' }} /> */}
            <div className='row ps-lg-2 pe-lg-2'>
                <div className='col-12 col-md-6 col-lg-3'>
                    <label for=""><b>{t('Dashboard.Work_Assigned_by_Officials.From_Date')}  :</b></label>
                    <input type="date"
                        className="form-control"
                        id="FromDate"
                        aria-describedby="emailHelp"
                        value={FromDate}
                        onChange={(e) => (setFromDate(e.target.value))} />
                </div>
                <div className='col-12 col-md-6 col-lg-3'>
                    <label for=""><b>{t('Dashboard.Work_Assigned_by_Officials.To_Date')} :</b></label>
                    <input type="date"
                        className="form-control"
                        aria-describedby="emailHelp"
                        id="ToDate"
                        value={ToDate}
                        onChange={(e) => setToDate(e.target.value)} />
                </div>
                {/* <div className='col-lg-3'>
                    <WorkTypeDDL
                        WorkType={WorkType}
                        setWorkType={setWorkType}
                        // IsPost={IsPost}
                        Language={Language}
                        IsPost={IsPost}
                        Flag1='Horticulture'
                    />
                </div> */}
                {/* <div className='col-lg-2'>
                    <ZoneUserNameDDL
                        ZoneNameDDLID={ZoneNameDDLID}
                        setZoneNameDDLID={setZoneNameDDLID}
                        Language={Language}
                        Flag='EmployeeAndContractorFlag'
                    />
                </div>
                <div className='col-lg-2'>
                    <WardUserDDL
                        WardName={WardName}
                        setWardName={setWardName}
                        Language={Language}
                        ZoneNameDDLID={ZoneNameDDLID}

                    />
                </div> */}
                <div className='col-lg-5'>
                    <button type="button"
                        className="clear btn btn-primary mt-lg-3"
                        onClick={handleClear}
                    >Clear</button>
                    {/* <div className='col-lg-2 pt-1'> */}

                    {/* <button type="button" className="btn btn-primary mx-3 mt-3" style={{ background: 'blue' }}
                        onClick={() => setShowGraph(!ShowGraph)}
                    >{ShowGraph ? 'Hide Graph' : 'Show Graph'}</button> */}

                    {/* </div> */}
                    {/* <span className=''>  */}
                    {/* <button type="button" className="btn btn-secondary">PDF</button> */}
                    <ExportEmployeeAndContractor
                        tableData={tableData}
                        name={ActiveButton == 'कर्मचारी' ? t('EmployeeAndContractor.Officer_Name_Pending_Work_Status') : t('EmployeeAndContractor.Contractor_Name_Pending_Work_Status')}
                        ActiveButton={ActiveButton}
                        FromDate={FromDate}
                        ToDate={ToDate}
                        WardName={WardName && WardName?.Label}
                        ZoneNameDDLID={ZoneNameDDLID && ZoneNameDDLID?.Label}
                        WorkType={WorkType && WorkType?.Label}
                    />
                    {/* </span>  */}
                </div>
            </div>

            {/* <hr className='fw-bold my-2' style={{ height: '2px solid black' }} /> */}
            <div className='row ps-lg-2 pe-lg-2 mt-1'>
                <div className={ShowGraph ? 'col-lg-6' : 'col-lg-12 px-0'} >
                    <div className="rounded2" style={{ backgroundColor: '#e5e1e1' }}>
                        <div style={{ height: '65vh' }} className={ShowGraph ? 'row mob_table table-responsive p-2' : 'row mob_table table-responsive p-2'}>
                            <table id="totalordergenerated"
                                cellPadding="0"
                                cellSpacing="0"
                                border="0"
                                className="table table-bordered">
                                <thead style={{ position: 'sticky', top: -10 }}>
                                    <tr>
                                        <th className="sr_no_col" style={{ width: "4%" }}>{t('Dashboard.Pcmc_Assets.Sr_No')}</th>

                                        {
                                            ActiveButton == 'कर्मचारी' ?
                                                <>
                                                    <th>{t('AdminDashboard.Admin_Sidebar_Name.Designation')}</th>
                                                    <th>{t('Dashboard.Work_Assigned_by_Officials.Officer_Name')}</th>
                                                    <th>{t('Dashboard.Pcmc_Assets.Zone_Name')}</th>

                                                </>
                                                :
                                                <>
                                                    <th>{t('Dashboard.Pcmc_Assets.Zone_Name')}</th>
                                                    <th>{t('Dashboard.Work_Assigned_by_Officials.Contractor_Name')}</th>
                                                </>
                                        }

                                        {/* <th>Department Name</th> */}

                                        <th>{t('EmployeeAndContractor.Total_Assign_Works')}</th>
                                        <th>{t('EmployeeAndContractor.Total_works_Completed')}</th>
                                        <th>{t('EmployeeAndContractor.Total_Pending_Works')}</th>
                                        <th>{t('EmployeeAndContractor.0_to_7_days')}</th>
                                        <th>{t('EmployeeAndContractor.7_to_15_days')}</th>
                                        <th>{t('EmployeeAndContractor.15_to_30_days')}</th>
                                        <th>{t('EmployeeAndContractor.30_to_90_days')}</th>
                                        <th>{t('EmployeeAndContractor.90_to_180_days')}</th>
                                        <th>{t('EmployeeAndContractor.1_year')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {
                                        tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((i) => (
                                            <tr style={{ width: '100%' }}>
                                                <td style={{ textAlign: "center" }}>{i?.rowNum}</td>
                                                {
                                                    ActiveButton == 'कर्मचारी' ?
                                                        <>
                                                            <td>-</td>
                                                            <td>{i.employeeName ? i.employeeName : '-'}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{i.zoneName ? i.zoneName : '-'}</td>
                                                        </>
                                                        :
                                                        <>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{i.zoneName ? i.zoneName : '-'}</td>
                                                            <td>{i.employeeName ? i.employeeName : '-'}</td>
                                                        </>
                                                }

                                              
                                                <td className='text-center fw-bold' style={{ color: '#4d8ae1' }}>
                                                    <span className='cursor-pointer fw-bold'
                                                        onClick={() => handleEmployeeAndContractorWorkPopup(i, 'All')}>
                                                        {i.totalAllCount ? i.totalAllCount : 0}
                                                    </span>
                                                </td>
                                                <td className='text-center fw-bold'>
                                                    <span className='cursor-pointer fw-bold' style={{ color: 'green' }}
                                                        onClick={() => handleEmployeeAndContractorWorkPopup(i, 'Completed')}>
                                                        {i.completedCount ? i.completedCount : 0}
                                                    </span>
                                                </td>
                                                <td className='text-center fw-bold'>
                                                    <span className='cursor-pointer fw-bold' style={{ color: 'orangered' }}
                                                        onClick={() => handleEmployeeAndContractorWorkPopup(i, 'Pending')}>
                                                        {i.pendingCount ? i.pendingCount : 0}
                                                    </span>
                                                </td>
                                                <td className='text-center fw-bold' style={{ color: '#fab74b' }}>
                                                    <span className='cursor-pointer'
                                                        onClick={() => handleEmployeeAndContractorWorkPopup(i, '0_7DaysCount')}>
                                                        {i.pending_0_7DaysCount ? i.pending_0_7DaysCount : 0}
                                                    </span>
                                                </td>

                                                <td className='text-center fw-bold' style={{ color: '#f7526f' }}>
                                                    <span className='cursor-pointer'
                                                        onClick={() => handleEmployeeAndContractorWorkPopup(i, '7_15DaysCount')}>
                                                        {i.pending_7_15DaysCount ? i.pending_7_15DaysCount : 0}
                                                    </span>
                                                </td>

                                                <td className='text-center fw-bold' style={{ color: '#30a1f5' }}>
                                                    <span className='cursor-pointer'
                                                        onClick={() => handleEmployeeAndContractorWorkPopup(i, '15_30DaysCount')}>
                                                        {i.pending_15_30DaysCount ? i.pending_15_30DaysCount : 0}
                                                    </span>
                                                </td>

                                                <td className='text-center fw-bold' style={{ color: '#db0008' }}>
                                                    <span className='cursor-pointer'
                                                        onClick={() => handleEmployeeAndContractorWorkPopup(i, '30_90DaysCount')}>
                                                        {i.pending_30_90DaysCount ? i.pending_30_90DaysCount : 0}
                                                    </span>
                                                </td>

                                                <td className='text-center fw-bold' style={{ color: '#77fb0a' }}>
                                                    <span className='cursor-pointer'
                                                        onClick={() => handleEmployeeAndContractorWorkPopup(i, '90_180DaysCount')}>
                                                        {i.pending_90_180DaysCount ? i.pending_90_180DaysCount : 0}
                                                    </span>
                                                </td>

                                                <td className='text-center fw-bold' style={{ color: '#6464ed' }}>
                                                    <span className='cursor-pointer'
                                                        onClick={() => handleEmployeeAndContractorWorkPopup(i, '365DaysCount')}>
                                                        {i.pending_365DaysCount ? i.pending_365DaysCount : 0}
                                                    </span>
                                                </td>

                                            </tr>
                                        ))
                                            :
                                            <>No Data Found</>
                                    } */}
                                    {tableData && tableData.data && tableData.data.length > 0 && tableData.data.map((zone, j) => {
                                        return zone?.rowdata?.map((i, officerIndex) => {
                                            const rowSpan = zone?.rowdata?.length || 1;
                                            return (
                                                <tr className="text-center" key={ActiveButton == 'कर्मचारी' ? `${zone?.designation}-${officerIndex}` : `${zone?.zoneName}-${officerIndex}`}>

                                                    {officerIndex === 0 ? (
                                                        <>
                                                            <td rowSpan={rowSpan} className="verticalAllign">{j + 1}</td>
                                                            {
                                                                ActiveButton == 'कर्मचारी' ?
                                                                    <td rowSpan={rowSpan} className="verticalAllign">{zone?.designation}</td>
                                                                    :
                                                                    <td rowSpan={rowSpan} className="verticalAllign">{zone?.zoneName}</td>

                                                            }
                                                        </>
                                                    ) : null}

                                                    {
                                                        ActiveButton == 'कर्मचारी' ?
                                                            <>
                                                                <td>{i.employeeName ? i.employeeName : '-'}</td>
                                                                <td>{i.zoneName ? i.zoneName : '-'}</td>
                                                            </>
                                                            :
                                                            <>
                                                                <td>{i.employeeName ? i.employeeName : '-'}</td>
                                                            </>
                                                    }


                                                    <td className='text-center fw-bold' style={{ color: '#4d8ae1' }}>
                                                        <span className='cursor-pointer fw-bold'
                                                            onClick={() => handleEmployeeAndContractorWorkPopup(i, 'All')}>
                                                            {i.totalAllCount ? i.totalAllCount : 0}
                                                        </span>
                                                    </td>
                                                    <td className='text-center fw-bold'>
                                                        <span className='cursor-pointer fw-bold' style={{ color: 'green' }}
                                                            onClick={() => handleEmployeeAndContractorWorkPopup(i, 'Completed')}>
                                                            {i.completedCount ? i.completedCount : 0}
                                                        </span>
                                                    </td>
                                                    <td className='text-center fw-bold'>
                                                        <span className='cursor-pointer fw-bold' style={{ color: 'orangered' }}
                                                            onClick={() => handleEmployeeAndContractorWorkPopup(i, 'Pending')}>
                                                            {i.pendingCount ? i.pendingCount : 0}
                                                        </span>
                                                    </td>
                                                    <td className='text-center fw-bold' style={{ color: '#fab74b' }}>
                                                        <span className='cursor-pointer'
                                                            onClick={() => handleEmployeeAndContractorWorkPopup(i, '0_7DaysCount')}>
                                                            {i.pending_0_7DaysCount ? i.pending_0_7DaysCount : 0}
                                                        </span>
                                                    </td>

                                                    <td className='text-center fw-bold' style={{ color: '#f7526f' }}>
                                                        <span className='cursor-pointer'
                                                            onClick={() => handleEmployeeAndContractorWorkPopup(i, '7_15DaysCount')}>
                                                            {i.pending_7_15DaysCount ? i.pending_7_15DaysCount : 0}
                                                        </span>
                                                    </td>

                                                    <td className='text-center fw-bold' style={{ color: '#30a1f5' }}>
                                                        <span className='cursor-pointer'
                                                            onClick={() => handleEmployeeAndContractorWorkPopup(i, '15_30DaysCount')}>
                                                            {i.pending_15_30DaysCount ? i.pending_15_30DaysCount : 0}
                                                        </span>
                                                    </td>

                                                    <td className='text-center fw-bold' style={{ color: '#db0008' }}>
                                                        <span className='cursor-pointer'
                                                            onClick={() => handleEmployeeAndContractorWorkPopup(i, '30_90DaysCount')}>
                                                            {i.pending_30_90DaysCount ? i.pending_30_90DaysCount : 0}
                                                        </span>
                                                    </td>

                                                    <td className='text-center fw-bold' style={{ color: '#147b09' }}>
                                                        <span className='cursor-pointer'
                                                            onClick={() => handleEmployeeAndContractorWorkPopup(i, '90_180DaysCount')}>
                                                            {i.pending_90_180DaysCount ? i.pending_90_180DaysCount : 0}
                                                        </span>
                                                    </td>

                                                    <td className='text-center fw-bold' style={{ color: '#6464ed' }}>
                                                        <span className='cursor-pointer'
                                                            onClick={() => handleEmployeeAndContractorWorkPopup(i, '365DaysCount')}>
                                                            {i.pending_365DaysCount ? i.pending_365DaysCount : 0}
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        });
                                    })
                                    }

                                </tbody>

                            </table>

                        </div>
                        {/* {tableData && tableData.table && tableData.table.length > 0 &&
                            <Pegination
                                PerPageCount={PerPageCount}
                                TotalCount={tableData.table[0].totalCount}
                                setFrom={setFrom}
                                setTo={setTo}
                                setrowNo={setrowNo}
                                CurrentPage={CurrentPage}
                                setCurrentPage={setCurrentPage}
                                IsClear={IsClear}
                            />
                        } */}
                    </div>
                </div>
                {
                    ShowGraph &&

                    <EmployeeAndContractPieChart
                        // height={100}
                        // // width={100}
                        // options={NewPieoptions}
                        // data={Receiveddata}
                        tableData={tableData}
                        headingsData=''
                        SpLoader=''
                        ShowGraph={ShowGraph}

                    />
                }

                {/* // <div className=''> */}
                {/* <div className="rounded2 pb-2 text-center" style={{ backgroundColor: '#e5e1e1' }}>
                          
                            <div
                                className="btn-group text-center mt-lg-2 bg-white"
                                role="group"
                                aria-label="Basic outlined example"
                                style={{ border: '1px solid #154779', borderRadius: '5px' }}
                            >
                                {
                                    ButtonOptions.map((button) => (
                                        <button
                                            style={{ whiteSpace: 'nowrap', borderLeft: '1px solid black', border: '1px solid black' }}
                                            onClick={() => handleGraphButtonClick(button.value)}
                                            className={GraphActiveButton === button.value
                                                ? "btn fw-bold px-1 activebutton text-white d-flex"
                                                : "text-black fw-bold btn px-1 d-flex hovernone"}
                                            type="button"
                                        >
                                            {button.label}
                                        </button>
                                    ))
                                }
                            </div> */}


                {/* // </div>
                    
                // } */}

            </div>


            {
                EmployeeAndContractorWorkPopupShow.show ?
                    <EmployeeAndContractorWorkPopup
                        open={EmployeeAndContractorWorkPopupShow.show}
                        cancelbtn={handleOnCloseClick}
                        ActiveButton={ActiveButton}
                        GridData={EmployeeAndContractorWorkPopupShow.data}
                        PopUpApiFlag={EmployeeAndContractorWorkPopupShow.flag}
                        ShowBy={EmployeeAndContractorWorkPopupShow.ShowBy}
                        FromDate={FromDate}
                        ToDate={ToDate}
                        WorkType={WorkType}
                        ZoneNameDDLID={ZoneNameDDLID}
                        WardName={WardName}
                        YearValue='0'
                        MonthValue='0'
                        AssetType = '0'
                        WorkActiveButton={WorkActiveButton}

                    />
                    : <></>
            }
        </div>
    )
}