import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import GardenMasterCard from './GardenMasterCard'
import { useTranslation } from 'react-i18next'


export default function OfficialMasterCardData(props) {

    const { Countdata, Language, HeaderFinancialYear, MonthValue, YearValue, YearLabel, MonthLabel, WardValue, WardLabel, ProjectTypeId, DashboardName, ZoneValue, ZoneLabel, ActiveMasterCard, setActiveMasterCard, handleActiveMasterCardClick,ActiveButton,setActiveButton,handleButtonClick } = props

    const { t } = useTranslation()

    const [searchParams] = useSearchParams()
    let Lang = searchParams.get("Lang")
    const navigate = useNavigate()

    // const data = [
    //     {
    //         'title': t('Dashboard.Pcmc_Assets.Garden_Master'),
    //         'cardName': 'py-2 gray2 cursor ',
    //         'border': 'green_border',
    //         'count': Countdata.GardenTotalCount ? Countdata.GardenTotalCount : '0',
    //         'navigate': '1',
    //         'imgPath': "./assets/img/pine-tree.png",
    //         'color': 'bg-gray card-footer px-3',

    //     },
    //     {
    //         'title': t('Dashboard.Pcmc_Assets.Road_Divider'),
    //         'cardName': 'py-2 blue cursor ',
    //         'border': 'blue_border',
    //         'count': Countdata.RoadDividerTotalCount ? Countdata.RoadDividerTotalCount : '0',
    //         'navigate': '2',
    //         'imgPath': "./assets/img/diversion.png",
    //         'color': 'bg-purples card-footer px-3',
    //     },
    //     {
    //         'title': t('Dashboard.Pcmc_Assets.Open_Space_master'),
    //         'cardName': 'py-2 green cursor ',
    //         'border': 'blue_border',
    //         'count': Countdata.OpenSpaceTotalCount ? Countdata.OpenSpaceTotalCount : '0',
    //         'navigate': '3',
    //         'imgPath': "./assets/img/tree.png",
    //         'color': 'bg-blue card-footer px-3',
    //     },
    //     {
    //         'title': t('Dashboard.Pcmc_Assets.Tree_Belt’s_master'),
    //         'cardName': 'py-2 blue cursor ',
    //         'border': 'blue_border',
    //         'count': Countdata.TreeBeltTotalCount ? Countdata.TreeBeltTotalCount : '0',
    //         'navigate': '4',
    //         'imgPath': "./assets/img/chainsaw.png",
    //         'color': 'bg-yellow card-footer px-3',
    //     },
    //     {
    //         'title': t('Dashboard.Pcmc_Assets.chowks_master'),
    //         'cardName': 'py-2 green cursor ',
    //         'border': 'green_border',
    //         'count': Countdata.ChowksTotalCount ? Countdata.ChowksTotalCount : '0',
    //         'navigate': '5',
    //         'imgPath': "./assets/img/intersection.png",
    //         'color': 'bg-green card-footer px-3',
    //     },
    //     {
    //         'title': t('Dashboard.Pcmc_Assets.Play_Equipments'),
    //         'cardName': 'py-2 blue cursor ',
    //         'border': 'blue_border',
    //         'count': Countdata.PlayEquipmentsTotalCount ? Countdata.PlayEquipmentsTotalCount : '0',
    //         'navigate': '6',
    //         'imgPath': "./assets/img/chainsaw.png",
    //         'color': 'bg-red card-footer px-3',
    //     },
    // ]

    const data = [
        {
            'title': t('Dashboard.Pcmc_Assets.Garden_Master'),
            'cardName': 'py-2 gray2 cursor ',
            'border': 'green_border',
            'count': Countdata.GardenTotalCount ? Countdata.GardenTotalCount : '0',
            'navigate': '1',
            'imgPath': "./assets/img/pine-tree.png",
            'color': ActiveMasterCard == '1' ? " fw-bold px-2 activebuttonofficials text-white card-footer px-3 " : "border-dark bg-whites card-footer px-3",
            'countcolour': ActiveMasterCard == '1' ? " text-white " : "text-dark",
        },
        {
            'title': t('Dashboard.Pcmc_Assets.Road_Divider'),
            'cardName': 'py-2 blue cursor ',
            'border': 'blue_border',
            'count': Countdata.RoadDividerTotalCount ? Countdata.RoadDividerTotalCount : '0',
            'navigate': '2',
            'imgPath': "./assets/img/diversion.png",
            'color': ActiveMasterCard == '2' ? " fw-bold px-2 activebuttonofficials text-white card-footer px-3 " : "border-dark bg-whites card-footer px-3",
            'countcolour': ActiveMasterCard == '2' ? " text-white " : "text-dark",

        },
        // {
        //     'title': t('Dashboard.Pcmc_Assets.Open_Space_master'),
        //     'cardName': 'py-2 green cursor ',
        //     'border': 'blue_border',
        //     'count': Countdata.OpenSpaceTotalCount ? Countdata.OpenSpaceTotalCount : '0',
        //     'navigate': '3',
        //     'imgPath': "./assets/img/tree.png",
        //     'color': ActiveMasterCard == '3' ? " fw-bold px-2 activebuttonofficials text-white card-footer px-3 " : "border-dark bg-whites card-footer px-3",
        //     'countcolour': ActiveMasterCard == '3' ? " text-white " : "text-dark",

        // },
        // {
        //     'title': t('Dashboard.Pcmc_Assets.Tree_Belt’s_master'),
        //     'cardName': 'py-2 blue cursor ',
        //     'border': 'blue_border',
        //     'count': Countdata.TreeBeltTotalCount ? Countdata.TreeBeltTotalCount : '0',
        //     'navigate': '4',
        //     'imgPath': "./assets/img/chainsaw.png",
        //     'color': ActiveMasterCard == '4' ? " fw-bold px-2 activebuttonofficials text-white card-footer px-3 " : "border-dark bg-whites card-footer px-3",
        //     'countcolour': ActiveMasterCard == '4' ? " text-white " : "text-dark",

        // },
        // {
        //     'title': t('Dashboard.Pcmc_Assets.chowks_master'),
        //     'cardName': 'py-2 green cursor ',
        //     'border': 'green_border',
        //     'count': Countdata.ChowksTotalCount ? Countdata.ChowksTotalCount : '0',
        //     'navigate': '5',
        //     'imgPath': "./assets/img/intersection.png",
        //     'color': ActiveMasterCard == '5' ? " fw-bold px-2 activebuttonofficials text-white card-footer px-3 " : "border-dark bg-whites card-footer px-3",
        //     'countcolour': ActiveMasterCard == '5' ? " text-white " : "text-dark",

        // },
        // {
        //     'title': t('Dashboard.Pcmc_Assets.Play_Equipments'),
        //     'cardName': 'py-2 blue cursor ',
        //     'border': 'blue_border',
        //     'count': Countdata.PlayEquipmentsTotalCount ? Countdata.PlayEquipmentsTotalCount : '0',
        //     'navigate': '6',
        //     'imgPath': "./assets/img/chainsaw.png",
        //     'color': ActiveMasterCard == '6' ? " fw-bold px-2 activebuttonofficials text-white card-footer px-3 " : "border-dark bg-whites card-footer px-3",
        //     'countcolour': ActiveMasterCard == '6' ? " text-white " : "text-dark",

        // },
    ]


    return (
        <div>
            {/* <div className="row"> */}
            {/* <h5 className="ml-4 py-2">{t('Dashboard.Pcmc_Assets.Pcmc_Assets')}</h5> */}
            {/* <h5 className="ml-4 py-2">{t('Dashboard.Titles.Master_Data')}</h5> */}
            {/* </div> */}

            <div className='row mast_row gar_new_mas px-2 m-0'>
                {
                    data && data.length > 0 && data.map((i, j) => {

                        return (
                            <div class=" col-sm-6 col-lg-2 mastercard px-1 mb-0">
                                <div className="card py-0 shadow mb-0 "
                                    onClick={() => (i.navigate == 1 || i.navigate == 2) ? handleActiveMasterCardClick(i.navigate) : ''}
                                    style={{
                                        cursor: i.navigate == 1 || i.navigate == 2 ? 'pointer' : 'not-allowed'
                                    }}
                                >
                                    <div className={i.color}>
                                        <div className="bg_gray row align-items-center">
                                            <div className="col-7">
                                                <p className="mb-0" style={{ whiteSpace: "nowrap", fontSize: '15px' }}><b>{i.title}</b></p>
                                            </div>
                                            <div className="col-5 text-end">
                                                <h4 className={i.countcolour}>{i.count}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="col-12 col-lg-8 d-flex align-items-center justify-content-end float-end">
                    <div className="btn-group  float-end" role="group" aria-label="Basic outlined example" style={{ border: '1px solid #cf4141', borderRadius: '5px', }}>
                        <button
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => handleButtonClick('उद्यान दैनंदिन कामे')}
                            className={ActiveButton == 'उद्यान दैनंदिन कामे' ? "btn fw-bold px-2 activebutton2 text-white d-flex border-ends2" : "text-black fw-bold btn px-1 d-flex hovernone border-ends2"}
                        > {t('Dashboard.Titles.Garden_Daily_Work')}</button>

                        <button
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => handleButtonClick('प्रशासनाने वाटप केलेली कामे')}
                            className={ActiveButton == 'प्रशासनाने वाटप केलेली कामे' ? "btn px-2 fw-bold activebutton2 text-white d-flex border-ends2" : "text-black fw-bold btn px-1 d-flex hovernone border-ends2"}
                            type="button">{t('Dashboard.Titles.Work_Assigned_by_Officials')}</button>
                        <button

                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => handleButtonClick('ठेकेदारनिहाय कामाची प्रगती')}
                            className={ActiveButton == 'ठेकेदारनिहाय कामाची प्रगती' ? "btn px-2 fw-bold activebutton2 text-white d-flex border-ends2" : "text-black fw-bold btn px-1 d-flex hovernone border-ends2"}
                            type="button">{t('Dashboard.Titles.Work_Progress_By_Contractor')}</button>

                        <button
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => handleButtonClick('पुन्हा वाटप केलेल्या कामाची प्रगती')}
                            className={ActiveButton == 'पुन्हा वाटप केलेल्या कामाची प्रगती' ? "btn px-2 fw-bold activebutton2 text-white d-flex " : "text-black fw-bold btn px-1 d-flex hovernone "}
                            type="button">{t('Dashboard.Titles.Work_Reopen_Progress_By_Contractor')}</button>
                    </div>
                </div>
            </div>


        </div>



    )

}

