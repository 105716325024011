
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

// Get Table Data
export const DailyPreventiveWorkTableDataAPI = createAsyncThunk("DailyPreventiveWorkTableData", async ({ data }) => {
    const {
        M_WardID,
        FromkDate,
        TokDate,
        M_UsersID,
        token,
        From,
        To,
        Flag,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_KPIUploadPreventiveWork_Select?M_WardID=${M_WardID ? M_WardID : '1'}&FromkDate=${FromkDate}&TokDate=${TokDate}&M_UsersID=${M_UsersID}&Flag=${Flag}&From=${From}&To=${To}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DailyPreventiveWorkTableDataSlice = createSlice({
    name: "DailyPreventiveWorkTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DailyPreventiveWorkTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DailyPreventiveWorkTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(DailyPreventiveWorkTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DailyPreventiveWorkTableDataReducer = DailyPreventiveWorkTableDataSlice.reducer


// New Daily Preventive Screen GET Api

export const NewScreenDailyPreventiveWorkTableDataAPI = createAsyncThunk("NewScreenDailyPreventiveWorkTableData", async ({ data }) => {
    const {
        T_KPIUploadDailyWorkID,
        FromDate,
        ToDate,
        M_UserID,
        M_ZoneID,
        M_OfficerID,
        M_DesignationID,
        Language,
        token,
        From,
        To,
        Flag,
        setGridData,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_KPIUploadDailyWork_Select?T_KPIUploadDailyWorkID=${T_KPIUploadDailyWorkID ? T_KPIUploadDailyWorkID : 0}&FromDate=${FromDate}&ToDate=${ToDate}&M_ZoneID=${M_ZoneID ? M_ZoneID : 0}&M_OfficerID=${M_OfficerID ? M_OfficerID : 0}&M_DesignationID=${M_DesignationID ? M_DesignationID : 0}&M_UserID=${M_UserID}&Language=${Language}&Flag=${Flag}&From=${From}&To=${To}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setGridData && setGridData(result.data)
                return result.data
                // console.log('result.data.result',result.data.result)
            } else {
                return result
            }
        })
})

const NewScreenDailyPreventiveWorkTableDataSlice = createSlice({
    name: "NewScreenDailyPreventiveWorkTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NewScreenDailyPreventiveWorkTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(NewScreenDailyPreventiveWorkTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(NewScreenDailyPreventiveWorkTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const NewScreenDailyPreventiveWorkTableDataReducer = NewScreenDailyPreventiveWorkTableDataSlice.reducer

//   PopUp GET Api


export const NewScreenDailyPreventiveWorkCountPopupDataAPI = createAsyncThunk("NewScreenDailyPreventiveWorkCountPopupData", async ({ data }) => {
    const {
        T_KPIUploadDailyWorkID,
        M_WorkTypeID,
        M_UserID,
        Language,
        token,
        To,
        Flag,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_KPIUploadDailyWork_CheckList_Select?T_KPIUploadDailyWorkID=${T_KPIUploadDailyWorkID ? T_KPIUploadDailyWorkID : 0}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : 0}&M_UserID=${M_UserID}&Language=${Language}&Flag=${Flag}&To=${To}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const NewScreenDailyPreventiveWorkCountPopupDataSlice = createSlice({
    name: "NewScreenDailyPreventiveWorkCountPopupData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NewScreenDailyPreventiveWorkCountPopupDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(NewScreenDailyPreventiveWorkCountPopupDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(NewScreenDailyPreventiveWorkCountPopupDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const NewScreenDailyPreventiveWorkCountPopupDataReducer = NewScreenDailyPreventiveWorkCountPopupDataSlice.reducer



// Get Table Data
export const GetChecklistProgressSelectDataAPI = createAsyncThunk("GetChecklistProgressSelectData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_ZoneID,
        M_AssetTypeID,
        M_WorkTypeID,
        M_WardID,
        M_UsersID,
        token,
        From,
        To,
        Flag,
        handleGetData,
        Language,
        Date,
        setloader
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

   
    // return fetch('https://apipcmcdaksha.cdat.work/APKDashboard/Get_WEB_GD_T_KPIUploadDailyWork_ChecklistProgress_Select?M_FinancialYearID=2024&M_MonthID=1&M_ZoneID=0&M_WardID=0&M_AssetTypeID=1&M_UsersID=0&Flag=Garden&From=1&To=1000', requestOptions)

    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_KPIUploadDailyWork_ChecklistProgress_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_ZoneID=${M_ZoneID}&M_WardID=${M_WardID}&M_AssetTypeID=${M_AssetTypeID}&M_WorkTypeID=${M_WorkTypeID}&M_UsersID=${M_UsersID}&Date=${Date}&Language=${Language}&Flag=${Flag}&From=${From}&To=${To}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            console.log(result)
            if (result.code >= 200 && result.code <= 300 && result) {
                setloader && setloader(false)
                handleGetData(result)
                return result
            } else {
                setloader && setloader(false)
                return result
            }
        })
})

const GetChecklistProgressSelectDataSlice = createSlice({
    name: "GetChecklistProgressSelectData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GetChecklistProgressSelectDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(GetChecklistProgressSelectDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(GetChecklistProgressSelectDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GetChecklistProgressSelectDataReducer = GetChecklistProgressSelectDataSlice.reducer

// Get Upload Daily Work Checklist Progress Wise Employee Select Data

export const ChecklistProgressWiseEmployeeSelectDataAPI = createAsyncThunk("ChecklistProgressWiseEmployeeSelectData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_ZoneID,
        M_AssetTypeID,
        M_WorkTypeID,
        M_OfficerID,
        M_UserID,
        Language,
        token,
        From,
        To,
        Flag,
        setGridData
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_KPIUploadDailyWork_ChecklistProgress_Wise_Employee_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_ZoneID=${M_ZoneID}&M_AssetTypeID=${M_AssetTypeID}&M_WorkTypeID=${M_WorkTypeID}&M_OfficerID=${M_OfficerID}&M_UserID=${M_UserID}&Language=${Language}&Flag=${Flag}&From=${From}&To=${To}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            console.log(result)
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                setGridData && setGridData(result.data.table)
                return result.data.table
            } else {
                return result.data
            }
        })
})

const ChecklistProgressWiseEmployeeSelectDataSlice = createSlice({
    name: "ChecklistProgressWiseEmployeeSelectData",
    initialState: {
        isTableLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ChecklistProgressWiseEmployeeSelectDataAPI.pending, (state, action) => {
            state.isTableLoading = true;
        });
        builder.addCase(ChecklistProgressWiseEmployeeSelectDataAPI.fulfilled, (state, action) => {
            state.isTableLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ChecklistProgressWiseEmployeeSelectDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isTableLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ChecklistProgressWiseEmployeeSelectDataReducer = ChecklistProgressWiseEmployeeSelectDataSlice.reducer

export const ChecklistProgressWiseEmplDailyPendingCompletedSelectDataAPI = createAsyncThunk("ChecklistProgressWiseEmplDailyPendingCompletedSelectData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_AssetID,
        M_AssetTypeID,
        M_OfficerID,
        T_KPIUploadDailyWorkID,
        M_UserID,
        Language,
        token,
        From,
        To,
        Flag,
        setGridData
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_KPIUploadDailyWork_ChecklistProgress_Wise_Employee_Popup_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&T_KPIUploadDailyWorkID=${T_KPIUploadDailyWorkID}&M_AssetID=${M_AssetID}&M_AssetTypeID=${M_AssetTypeID}&M_UserID=${M_UserID}&Language=${Language}&Flag=${Flag}&From=${From}&To=${To}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            console.log(result)
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                setGridData && setGridData(result.data.table)
                return result.data.table
            } else {
                return result.data
            }
        })
})

const ChecklistProgressWiseEmplDailyPendingCompletedSelectDataSlice = createSlice({
    name: "ChecklistProgressWiseEmplDailyPendingCompletedSelectData",
    initialState: {
        isTableLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ChecklistProgressWiseEmplDailyPendingCompletedSelectDataAPI.pending, (state, action) => {
            state.isTableLoading = true;
        });
        builder.addCase(ChecklistProgressWiseEmplDailyPendingCompletedSelectDataAPI.fulfilled, (state, action) => {
            state.isTableLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ChecklistProgressWiseEmplDailyPendingCompletedSelectDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isTableLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ChecklistProgressWiseEmplDailyPendingCompletedSelectDataReducer = ChecklistProgressWiseEmplDailyPendingCompletedSelectDataSlice.reducer