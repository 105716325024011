
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { FinancialYearDDLAPI, MonthDDLAPI, WardNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { useTranslation } from 'react-i18next'

export const WardnameHeaderDDL = (props) => {
    const { WardName, setWardName, WLable, HWard, Flag, Language, M_ZoneID, WardDDL, ZLable, ZWard, rowData } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const d = new Date();
    let month = d.getMonth();

    const [MonthID, setMonthID] = useState(month + 1)

    useEffect(() => {
        const data = {
            UserID,
            token: token,
            M_ZoneID: M_ZoneID,
            Language: Language.Label,
        }
        // if (M_ZoneID != 0) {
            { Language.Label != '' && dispatch(WardNameDDLAPI({ data })) }
        // }

    }, [Language.Label, M_ZoneID, ZLable, ZWard])

    const { WardDDLData } = useSelector(state => state.WardNameDDLData)


    useEffect(() => {
        handleWardNameDDL()
    }, [WardDDLData])

    const handleWardNameDDL = () => {
        if (WardDDLData && WardDDLData.table && WardDDLData.table.length > 0) {
            let list = WardDDLData.table.map((item, index) => ({
                value: item.id,
                label: item.wardName,
            }))

            if (WardDDL == 'DashboardWard' || localStorage.getItem('WardID')) {
                setWardName({
                    DDL: list,
                    ID: localStorage.getItem('WardID') ? localStorage.getItem('WardID') : 0,
                    Label: localStorage.getItem('WardLabel') ? localStorage.getItem('WardLabel') : "Select...",
                })
            }
            else {
                setWardName({
                    DDL: list,
                    ID: Flag === 'Update' ? rowData.id : HWard ? HWard : list[0].value,
                    Label: Flag === 'Update' ? rowData.wardName : WLable ? WLable : list[0].label
                    // ID: HWard && HWard ? HWard : '0',
                    // Label: WLable && WLable ? WLable : "Select...",
                })
            }
        }
        else {
            setWardName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    const handleChange = (e) => {
        setWardName({ ...WardName, ID: e.value, Label: e.label })
        if (WardDDL == 'DashboardWard') {
            localStorage.setItem('WardID', JSON.stringify(e.value))
            localStorage.setItem('WardLabel', e.label)
        }
    }
    const handleChangeClear = () => {
        setWardName({ ...WardName, ID: 0, Label: "Select..." })
        localStorage.setItem('WardID', '0')
        localStorage.setItem('WardLabel', "Select...")
    }
    return (
        <div className={WardDDL == 'DashboardWard' ? "form-group d-flex mr-2" : 'form-group'} >
            <label className={WardDDL == 'DashboardWard' ? "d-block mr-2 mt-2" : 'd-block'} htmlFor="NameofDepartment"><b>{t('Dashboard.Titles.Ward_Name')}</b></label>

            <Select
                // styles={{
                //     control: (baseStyles, state) => ({
                //         ...baseStyles,
                //         borderColor: state.isFocused ? null : 'transparent',
                //         borderBottomColor: 'black',
                //         boxShadow: 'none'
                //     }),
                //     option: (provided, state) => ({
                //         ...provided,
                //         color: 'black'
                //     })
                // }}
                // components={{
                //     IndicatorSeparator: () => null
                // }}
                isClearable={WardDDL == 'DashboardWard' ? true : false}
                // isClearable={true}
                value={{ value: WardName.ID, label: WardName.Label }}
                onChange={(e) => {
                    e ?
                        // setWardName({ ...WardName, ID: e.value, Label: e.label })
                        handleChange(e)
                        :
                        handleChangeClear()

                    // setWardName({ ...WardName, ID: 0, Label: "Select..." })

                }}
                options={WardName.DDL}
            />

        </div>
    )
}