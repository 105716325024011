export const Marathi = {
    // ठेकेदार

    "Dashboard": {
        "Titles": {
            "Ward_Name": "प्रभाग क्र.",
            "Work_Assigned_by_Officials": "प्रशासनाने वाटप केलेली कामे ",
            "Garden_Daily_Work": "उद्यान दैनंदिन कामे",
            "Horticulture_Work_Assigned_by_Officials": "उद्यान कामे",
            "Civil_Work_Assigned_By_Officials": "सिव्हिल कामे",
            "Electrical_Work_Assigned_By_Officials": "इलेक्ट्रिकल कामे",
            "Sports_works": "क्रीडा कामे",
            "Work_Progress_By_Contractor": "ठेकेदारनिहाय कामाची प्रगती",
            "Horticulture_Work_Progress_By_Contractor": "उद्यान कामाची प्रगती",
            "Civil_Work_Progress_By_Contractor": "सिव्हिल कामाची प्रगती",
            "Electrical_Work_Progress_By_Contractor": "इलेक्ट्रिकल कामाची प्रगती",
            "Sports_works_Progress": "क्रीडा कामाची प्रगती",
            "Assigned_work": "वाटप कामे",
            "Pending": "प्रलंबित कामे",
            "Completed": "पूर्ण झालेली कामे",
            "Work_Reopen_Progress_By_Contractor": "ठेकेदारनिहाय पुन्हा वाटप केलेल्या कामाची प्रगती",
            "Horticulture_work_reopen_progress": "उद्यान कामाची प्रगती",
            "Reopened_Work": "पुन्हा वाटप केलेली कामे",
            "Civil_Work_Reopen_Progress_By_Contractor": "सिव्हिल कामाची प्रगती",
            "Electrical_Work_Reopen_Progress_By_Contractor": "इलेक्ट्रिकल कामाची प्रगती",
            "Master_Data": "मास्टर डेटा",
            "Sports_works_Reopen_By_Contractor": "क्रीडा कामाची प्रगती",
            "Schedule_Date": "शेड्युलिंग दिनांक",
            "Attendance": "हजेरी",
            "Assigned_Work_Completed": "वाटप कामे केलेली उद्याने",
            "Work_Completed": "पूर्ण कामे केलेली उद्याने",
            "Road_Divider" : 'रोड दुभाजक',
        },
        "Pcmc_Assets": {
            "Pcmc_Assets": "PCMC मालमत्ता",
            "Ward_no": "निवडणूक प्रभाग क्र",
            "Sector_Name": "सेक्टरचे नाव",
            "Maintain_By": "देखभाल व दुरुस्ती द्वारा",
            "Sr_No": "अ.क्र",
            "Area_Name": "क्षेत्राचे नाव ",
            "Garden_Name": "उद्यानाचे नाव",
            "Allocation_checklist": "वाटप चेकलिस्ट",
            "Pending_checklist": "प्रलंबित  चेकलिस्ट",
            "Completed_checklist": "पूर्ण केलेली चेकलिस्ट",
            "Image": "फोटो",
            "Track": "ट्रॅक",
            "Action": "कृती",
            "Asset_Type": "मालमत्ता प्रकार",
            "Asset_Name": "मालमत्तेचे नाव",
            "Area": "क्षेत्रफळ (हेक्टरमध्ये)",
            "Area_sq": "क्षेत्रफळ (चौ. मीटरमध्ये)",
            "Status": "स्थिती ",
            "Road_Divider_Name": "रस्ता दुभाजकाचे नाव",
            "Open_Space": "मोकळ्या जागेचे नाव",
            "Tree_Belt_Name": "ट्री बेल्टचे नाव",
            "chowks_Name": "चौकाचे नाव",
            "chowks_master": "चौक",
            "Tree_Belt’s_master": 'ट्री बेल्ट',
            "Play_Equipments": 'क्रीडा साहित्य',
            "Open_Space_master": "मोकळी जागा",
            "Road_Divider": "रस्ता दुभाजक",
            "Garden_Master": "उद्यान",
            // "Zone_Name": "प्रशास्किय प्रभाग",
            "Zone_Name": "झोनचे नाव",
            "Equipment_Name": "उपकरणाचे नाव",
            "Supplier_Name": "ठेकेदाराचे नाव",
            "Area_Square_meter": "क्षेत्रफळ (मीटरमध्ये)"
        },
        "Work_Assigned_by_Officials": {
            "From_Date": "दिनांक पासून",
            "To_Date": "दिनांक पर्यंत",
            "Contractor_Name": "ठेकेदाराचे नाव",
            "Assign_Date": "वाटप केलेल्या कामाची दिनांक",
            "Area1": "क्षेत्राचे नाव",
            "Work_Name": "कामाचे नाव",
            "Assign_Work_Photo": "वाटप कामाचा फोटो",
            "Officer_Name": "अधिकाऱ्याचे नाव",
            "Assign_Contractor_Name": "ठेकेदाराचे नाव",
            "Assigned_By": "प्रशासनाचे नाव",
            "Completed_Work_Date": "पूर्ण झालेल्या कामाचे दिनांक",
            "Completed_Work_Photo": "पूर्ण झालेल्या कामाचा फोटो",
            "BenchMark": "बेंचमार्क",
            "Reopened_By": "प्रशासनाचे नाव",
            "Reopened_Photo": "पुन्हा टाकलेल्या कामाचा फोटो",
            "Work_Date": "पूर्ण केलेल्या कामाची दिनांक"
        }
    },
    "Sidebar": {
        "Dashboard": "डॅशबोर्ड",
        "Preventive_Work_Report": "प्रतिबंधात्मक कार्य अहवाल",
        "Completed_Work_Report": "पूर्ण झालेल्या कामाचा अहवाल",
        "Garden_Report": "उद्यान अहवाल",
        "Employee_Tracking": "कर्मचारी ट्रॅकिंग",
        "Work_Type": "कामाचे प्रकार",
        "Upload_Date": "दिनांक अपलोड करा",
        "Work_Assign_By": "कार्य द्वारे नियुक्ती करा",
        "Work_Done_Date": "काम झाल्याची दिनांक ",
        "Before_Work_Photo": "कामाच्या आधीचा फोटो",
        "After_Work_Photo": "पूर्ण कामाचा फोटो",
        "Role": "रोल",
        "Daily_Work_Sheet": 'दैनंदिन कामाचे तपशील',

    },


    "AdminDashboard": {
        "Admin_Titles": {
            "Daily_Preventive_Work": "दैनिक प्रतिबंधात्मक कार्य",
            "Work_Progress_by_Contractors": "ठेकेदारांकडून कामाची प्रगती",
            "Reopen_Work_Progress": "कामाची प्रगती पुन्हा उघडा",
            "Deputy_Municipal_Commissioner_(DMC)": "उपमहापालिका आयुक्त (DMC)",
            "Garden_Officer_(GO / AGO)": "उद्यान अधिकारी (GO / AGO)",
            "Garden_Assistant_(GA)": "उद्यान सहाय्यक (GA)",
            "Garden_Supervisor_(GS)": "उद्यान पर्यवेक्षक (GS)",
            "Reopen_Pending": "प्रलंबित पुन्हा उघडा",
            "Reopen_Completed": "पूर्ण झालेले कार्य पुन्हा उघडा",



            "Total_Work": 'एकूण कामे',
            'Total_works_Completed': 'पूर्ण केलेल्या कामे',
            "Pending_Work": 'प्रलंबित कामे',
            'Daily_Work_Completed': 'दैनंदिन पूर्ण केलेली कामे',
            'Daily_Work_Pending': 'दैनंदिन प्रलंबित कामे',
            'Remark': 'शेरा'
        },

        "Grid_Heading": {
            "Daily_Preventive_Work_Pending": "प्रलंबित दैनिक प्रतिबंधात्मक काम",
            "Daily_Preventive_Work_Completed": "पूर्ण झालेले दैनिक प्रतिबंधात्मक कार्य ",
            "WORK_ASSIGNED": "नियुक्त केलेले काम",
            "WORK_ASSIGNED_Deputy_Municipal_Commissioner_(DMC)": "नियुक्त केलेले काम / उपमहापालिका आयुक्त (DMC)",
            "WORK_ASSIGNED_Garden_Officer_(GO / AGO)": "नियुक्त केलेले काम / उद्यान अधिकारी (GO / AGO)",
            "WORK_ASSIGNED_Garden_Assistant_(GA)": "नियुक्त केलेले काम / उद्यान सहाय्यक (GA)",
            "WORK_ASSIGNED_Garden_Supervisor_(GS)": "नियुक्त केलेले काम / उद्यान पर्यवेक्षक (GS)",
            "Reopen_Work_Progress_Reopen_Pending": "प्रलंबित कामाची प्रगती पुन्हा उघडा",
            "Reopen_Work_Progress_Reopen_Completed": "पुन्हा उघडलेले कामाची प्रगती",
        },

        "Table_Heading": {
            "Date": "दिनांक",
            "Work_Sub_Type": "कामाचे उप प्रकार",
            "Reopen_Date": "पुन्हा उघडण्याची तारीख",
            "Reopen_Work_Photo": "कामाचा फोटो पुन्हा उघडा",
            "Work_Reopen_Date": "कार्य पुन्हा उघडण्याची तारीख",
            "Reopen_Completed_Work_Date": "पूर्ण झालेल्या कामाची तारीख",
            "Reopen_Completed_Work_Photo": "पूर्ण झालेल्या कामाचा फोटो पुन्हा उघडा",
        },

        "Admin_Sidebar_Name": {
            "Master": "मास्टर",
            "Garden_Reports": "गार्डन अहवाल",
            "Designation": "पदनाम",
            "Employee": "अधिकारी/कर्मचारी माहिती",
            "User_Creation": "यूजर तयार करणे",
            "Ward_Master": "निवडणूक प्रभाग मास्टर",
            "Sector_Master": "सेक्टर मास्टर",
            "Work": "उद्यान कामे ",
            "Project_Name": "प्रकल्पाचे नाव",
            "Work_Sub_Type": "कामाचे उप प्रकार",
            "Contractor_Wise_Assets": "ठेकेदार नुसार उद्यान",
            "Project_Wise_Contractor": "प्रकल्प नुसार ठेकेदार ",
            // "Project_Wise_Work_Name": "प्रकल्पानुसार कामाचे नाव",
            "Garden_wise_maintenance_status": "उद्यान निहाय देखभालीची स्थिती",
            "User_Wise_Ward_Mapping": "यूजर नुसार मॅपिंग"
        },

        "Admin_Sidebar_Grid_Heading": {
            "Designation_Master": "पदनाम मास्टर",
            "Employee_Master": "कर्मचारी मास्टर",
            "User_Creation_Master": "वापरकर्ता निर्माण मास्टर",
            "Contractor_Name_Master": "ठेकेदाराचे नाव मास्टर",
            "Zone_Master": "प्रशासकीय प्रभाग",
            "Sector_Garden_Master": "सेक्टर गार्डन मास्टर",
            "Work_Type_Garden_Master": "उद्यान कामाचा प्रकार मास्टर",
            "Work_Sub_Type_Garden_Master": "उद्यान कामाचे उप प्रकार मास्टर",
            "Work_Garden_Master": "उद्यानाचे काम मास्टर",
            "Project_Name_Master": "प्रकल्पाचे नाव मास्टर",
            "Project_Wise_Contractor_Master": "प्रकल्पनिहाय ठेकेदार मास्टर",
            "Project_Wise_Work_Name_Master": "प्रकल्पानुसार कामाचे नाव मास्टर",
        },

        "Admin_Sidebar_Table_Heading": {
            "Designation_Name": "पदनाम",
            "Mobile_Number": "मोबाइल क्रमांक",
            "Email_Id": "ई-मेल आयडी",
            "Table_User_Name": "सारणी वापरकर्ताचे नाव",
            "User_Name": "वापरकर्ताचे नाव",
            "Password": "पासवर्ड",
            "Address": "पत्ता",
            "Contact_Person_Name": "संपर्क व्यक्तीचे नाव",
            "Zone_Name": "झोनचे नाव",
            "Prabhag_No": "प्रभाग क्र",
            "Benchmark_in_Days": "दिवसांमध्ये",
            "Penalty_Amount_(Rs.)": "दंडाची रक्कम (रु.)",
            "Project_Type": "प्रकल्पाचा प्रकार",
            "User": "वापरकर्ता",
            "Equipment_No.": "उपकरणे क्र.",
            "Equipment_Installation_Date": 'उपकरणे स्थापनेची तारीख',
            "Visited_Date": 'भेट दिलेली तारीख',
            "Work_Description": 'कामाचे वर्णन',
            "Department": 'विभाग',
            "Photo": 'फोटो',
            "Work_Photo": "कामाचा फोटो",
            "After_Work_Photo": 'कामानंतरचा फोटो',
            "Before_Work_Photo": 'कामाच्या आधीचा फोटो '
        },
    },
    "EmployeeAndContractor": {
        "Officer_Name_Pending_Work_Status": "कर्मचारीनिहाय ठेकेदारांच्या प्रलंबित कामाचा गोषवारा",
        "Contractor_Name_Pending_Work_Status": "ठेकेदाऱ्यांचा प्रलंबित कामाचा गोषवारा",
        "Total_Assign_Works": "एकूण वाटप केलेल्या कामे",
        "Total_works_Completed": "एकूण पूर्ण केलेल्या कामे",
        "Total_Pending_Works": "एकूण प्रलंबित कामे",
        "Total": 'एकूण',
        "0_to_7_days": "0 ते 7 दिवस",
        "7_to_15_days": "7 ते 15 दिवस",
        "15_to_30_days": "15 ते 30 दिवस",
        "30_to_90_days": "30 ते 90 दिवस",
        "90_to_180_days": "90 ते 180 दिवस",
        "1_year": "1 वर्षा पुढील",
        "Karmachari": 'कर्मचारी',
        'Thekedar': 'ठेकेदार',
        'Karmachariche_Nav': 'कर्माचाऱ्याचे नांव',
        'Thekedaryache_Nav': 'ठेकेदाऱ्यांचे नांव',
        'Daily_Work': 'दैनिक प्रतिबंधात्मक कामे',
        'Yes': 'होय',
        'No': 'नाही',
        'Count': 'संख्या',
        'ChecklistPhoto': 'चेकलिस्ट फोटो',
        'Checklist':'चेकलिस्ट',
        'ChecklistName':'चेकलिस्ट नाव',
        'VisitTarget' : 'एकूण टार्गेट',
        'Achievement' : 'पूर्ण',
        'Pending' : 'प्रलंबित'


    }
}