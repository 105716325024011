import { useEffect, useState } from "react";
import { t } from "i18next";
import { useAuthState } from "../../../../../../Helper/Context";
import { useDispatch, useSelector } from "react-redux";
import { ChecklistProgressWiseEmployeeSelectDataAPI } from "../../../../../../Redux/GardenDashboardSlice/DailyPreventiveWorkSlice";
import { Loading } from "../../../../../../Helper/Loading";
import CompletechecklistPopUp from "./CompletechecklistPopUp";
import PendingChecklistPopUp from "./PendingChecklistPopUp";
import DailyChecklistPopUp from "./DailyChecklistPopUp";


export default function VisitAchievePendingModal(props) {

    const { handleOpenModal, setOpenModal, Title, Flag, Data, apiFlag, LableName, handleIsPost, ShowAddModal, IsClear, setIsClear, YearValue, MonthValue, WardValue, ZoneValue, Date, MainDataID, AssetType, WorkActiveButton } = props
    // const { paramName, paramNameID } = LableName

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()
    const [GridData, setGridData] = useState([])

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    // --------------Completed Checklist PopUp -------------

    const [CompleteChecklistModal, setCompleteChecklistModal] = useState(false)
    const [AcheivementChecklistData, setAcheivementChecklistData] = useState([])
    const [Heading, setHeading] = useState('')

    const handleCompleteChecklistModal = (data, heading) => {
        setCompleteChecklistModal(!CompleteChecklistModal)
        setAcheivementChecklistData(data)
        setHeading(heading)

    }

    // --------------Pending checklist Popup --------------

    const [AddChecklist, setAddChecklist] = useState(false)
    const [PendingData, setPendingData] = useState([])

    const handleChecklist = (data, heading) => {
        setAddChecklist(!AddChecklist)
        setPendingData(data)
        setHeading(heading)
    }

    // -------------Daily Checklist ----------

    const [DailyChecklist, setDailyChecklist] = useState(false)
    const [DailyChecklistData, setDailyChecklistData] = useState([])

    const handleDailyChecklist = (data, heading) => {
        setDailyChecklist(!DailyChecklist)
        setDailyChecklistData(data)
        setHeading(heading)
    }

    const handleGardenData = (result) => {
        setGridData(result)
    }

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_MonthID: MonthValue,
            // M_ZoneID: ZoneValue,
            M_ZoneID: Data?.m_ZoneID,
            M_OfficerID: Data?.m_EmployeeID,
            M_AssetTypeID: AssetType,
            M_WorkTypeID: WorkActiveButton,
            M_WardID: WardValue,
            M_UserID: UserID,
            Language: Language.Label,
            token: token,
            From: '1',
            To: '99999',
            Flag: apiFlag,
            setGridData: setGridData
        }
        {
            (YearValue && Language.Label != '') &&
                dispatch(ChecklistProgressWiseEmployeeSelectDataAPI({ data }))
        }
    }, [Language.Label, YearValue, MonthValue, ZoneValue, WardValue,WorkActiveButton])

    const { isTableLoading } = useSelector(state => state.ChecklistProgressWiseEmployeeSelectData)

    return (
        <div style={{ zIndex: '99999' }}>
            <div
                className="modal fade show"
                style={{ display: "block", backgroundColor: "#00000055" }}
            >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable mt-5 pt-2 pt-lg-4" role="document">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h5 className="modal-title ms-4 fw-bold" id="exampleModalLongTitle">
                                {/* {Title} */}
                                <span className="text-danger">{Title} </span>
                            </h5>
                            <div>
                                <button
                                    onClick={() => setOpenModal(false)}
                                    type="button"
                                    className="close"
                                    style={{ marginLeft: '1em' }}
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                        </div>
                        {isTableLoading && <Loading />}
                        <div className="modal-body mx-4" style={{ marginTop: '-2em' }}>
                            <div className="row" >
                                <div className="table-responsive pt-2 " style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                    <table className="table table-striped ">
                                        <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                            <tr className="">

                                                <th className="w-6 px-3">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                                <th>{t('Dashboard.Pcmc_Assets.Garden_Name')}</th>
                                                {/* <th>{t('Dashboard.Pcmc_Assets.Allocation_checklist')}</th> */}
                                                {
                                                    Flag == 'Achievement' ?
                                                        <>
                                                            <th>{t('Dashboard.Pcmc_Assets.Allocation_checklist')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Completed_checklist')}</th>
                                                            {/* <th>{t('Dashboard.Pcmc_Assets.Pending_checklist')}</th> */}

                                                        </>
                                                        : (Flag == 'Pending') ?
                                                            <>
                                                                <th>{t('Dashboard.Pcmc_Assets.Allocation_checklist')}</th>
                                                                <th>{t('Dashboard.Pcmc_Assets.Pending_checklist')}</th>
                                                                <th>{t('Dashboard.Pcmc_Assets.Completed_checklist')}</th>

                                                            </>
                                                            :
                                                            ''

                                                }

                                            </tr>

                                        </thead>
                                        <tbody>
                                            {
                                                GridData && GridData.length > 0 ? GridData.map((i, j) =>
                                                    <tr>
                                                        <td className="text-center" width='5%'>{i?.rowNum}</td>
                                                        <td>{i?.assetName ? i?.assetName : '-'}</td>
                                                        {/* <td >{i?.visitTarget ? i?.visitTarget : '0'}</td> */}

                                                        {
                                                            Flag == 'Achievement' ?
                                                                <>
                                                                    <td className="cursor-pointer"
                                                                        onClick={(e) => i?.visitTarget == 0 ? e.preventDefault() : handleDailyChecklist(i, "Daily checklist")} title="Click"

                                                                    >{i?.visitTarget ? i?.visitTarget : '0'}</td>
                                                                    <td className="cursor-pointer"
                                                                        onClick={(e) => i?.achievement == 0 ? e.preventDefault() : handleCompleteChecklistModal(i, "achievement checklist")} title="Click"
                                                                    >{i?.achievement ? i?.achievement : '0'}</td>
                                                                    {/* <td className="cursor-pointer"
                                                                    onClick={(e) => i?.pending == 0 ? e.preventDefault() : handleChecklist(i, "Pending checkist")} title="Click"
                                                                >{i?.pending ? i?.pending : '0'}</td> */}

                                                                </>
                                                                : (Flag == 'Pending') ?
                                                                    <>
                                                                        <td className="cursor-pointer"
                                                                            onClick={(e) => i?.visitTarget == 0 ? e.preventDefault() : handleDailyChecklist(i, "Daily checklist")} title="Click"

                                                                        >{i?.visitTarget ? i?.visitTarget : '0'}</td>
                                                                        <td className="cursor-pointer"
                                                                            onClick={(e) => i?.pending == 0 ? e.preventDefault() : handleChecklist(i, "Pending checklist")} title="Click"
                                                                        >{i?.pending ? i?.pending : '0'}</td>
                                                                        <td className="cursor-pointer"
                                                                            onClick={(e) => i?.achievement == 0 ? e.preventDefault() : handleCompleteChecklistModal(i, "achievement checklist")} title="Click"

                                                                        >{i?.achievement ? i?.achievement : '0'}</td>
                                                                    </>
                                                                    :
                                                                    ''

                                                        }
                                                        {/* <button type="button" class="btn btn-sm px-2 py-0" style={{ background: 'grey', color: 'white' }}
                                                        onClick={() => (
                                                            handleDailyWorkTotalCountPopupShow(i, 'Pending'),
                                                            setDailyWorkPopupHeading(t('AdminDashboard.Admin_Titles.Pending_Work'))
                                                        )}
                                                    >{i.kpiPendingCount ? i.kpiPendingCount : '0'}</button> */}



                                                    </tr>
                                                ) :
                                                    <td colSpan={15}>No Found data</td>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
            </div >
            {
                CompleteChecklistModal ?
                    <CompletechecklistPopUp
                        cancelbtn={handleCompleteChecklistModal}
                        heading={t('Dashboard.Pcmc_Assets.Completed_checklist')}
                        Data={AcheivementChecklistData}
                        Heading={Heading}
                        YearValue={YearValue}
                        MonthValue={MonthValue}
                        AssetType={AssetType}
                        APIFlag={(apiFlag == 'Achievement_Week1' || apiFlag == 'Pending_Week1') ? 'CompleteChecklist_Week1' :
                            (apiFlag == 'Achievement_Week2' || apiFlag == 'Pending_Week2') ? 'CompleteChecklist_Week2' :
                                (apiFlag == 'Achievement_Week3' || apiFlag == 'Pending_Week3') ? 'CompleteChecklist_Week3' :
                                    (apiFlag == 'Achievement_Week4' || apiFlag == 'Pending_Week4') ? 'CompleteChecklist_Week4' :
                                        (apiFlag == 'Achievement_Total' || apiFlag == 'Pending_Total') && 'CompleteChecklistTotal'}
                    /> : <></>
            }
            {
                AddChecklist ?
                    <PendingChecklistPopUp
                        cancelbtn={handleChecklist}
                        heading=''
                        Data={PendingData}
                        Heading={t('Dashboard.Pcmc_Assets.Pending_checklist')}
                        YearValue={YearValue}
                        MonthValue={MonthValue}
                        AssetType={AssetType}
                        APIFlag={(apiFlag == 'Achievement_Week1' || apiFlag == 'Pending_Week1') ? 'PendingChecklist_Week1' :
                            (apiFlag == 'Achievement_Week2' || apiFlag == 'Pending_Week2') ? 'PendingChecklist_Week2' :
                                (apiFlag == 'Achievement_Week3' || apiFlag == 'Pending_Week3') ? 'PendingChecklist_Week3' :
                                    (apiFlag == 'Achievement_Week4' || apiFlag == 'Pending_Week4') ? 'PendingChecklist_Week4' :
                                        (apiFlag == 'Achievement_Total' || apiFlag == 'Pending_Total') && 'PendingChecklistTotal'}

                    /> : <></>
            }
            {
                DailyChecklist ?
                    <DailyChecklistPopUp
                        cancelbtn={handleDailyChecklist}
                        heading=''
                        Data={DailyChecklistData}
                        Heading={t('Dashboard.Pcmc_Assets.Allocation_checklist')}
                        YearValue={YearValue}
                        MonthValue={MonthValue}
                        AssetType={AssetType}
                        APIFlag={(apiFlag == 'Achievement_Week1' || apiFlag == 'Pending_Week1') ? 'Checklist_Week1' :
                            (apiFlag == 'Achievement_Week2' || apiFlag == 'Pending_Week2') ? 'Checklist_Week2' :
                                (apiFlag == 'Achievement_Week3' || apiFlag == 'Pending_Week3') ? 'Checklist_Week3' :
                                    (apiFlag == 'Achievement_Week4' || apiFlag == 'Pending_Week4') ? 'Checklist_Week4' :
                                        (apiFlag == 'Achievement_Total' || apiFlag == 'Pending_Total') && 'ChecklistTotal'}

                    /> : <></>
            }

        </div>

    );
}
