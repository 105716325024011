
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { MonthDDLAPI, } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'

export const MonthHeaderDDL = (props) => {
    const { Month, setMonth, MLable, HMonth, flag } = props

    // console.log("flag",flag)

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()


    const d = new Date();
    let month = d.getMonth();

    const [MonthID, setMonthID] = useState(month + 1)

    useEffect(() => {
        const data = { UserID, token }
        dispatch(MonthDDLAPI({ data }))
    }, [])

    const { MonthData } = useSelector(state => state.MonthDDLData)

    useEffect(() => {
        handleMonthDDL()
    }, [MonthData])

    const handleMonthDDL = () => {
        if (MonthData && MonthData.table && MonthData.table.length > 0) {
            let list = MonthData.table.map((item, index) => ({
                value: item.id,
                label: item.month_Name,
            }))
            if (flag == 'Screen') {
                setMonth({
                    DDL: list,
                    // ID: HMonth,
                    // Label: MLable,
                    ID: 0,
                    Label: "Select Month...",
                })
            } else {
                setMonth({
                    DDL: list,
                    ID: list[month].value,
                    Label: list[month].label,
                    // ID: 0,
                    // Label: "Select Month...",
                })
            }
        }
        else {
            setMonth({
                DDL: [],
                ID: 0,
                Label: "Select Month...",
            })
        }

    }



    return (
        <div className="form-group">
            {/* <label className="d-block " htmlFor="NameofDepartment"><b>Month :</b></label> */}
            <Select

                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? null : 'transparent',
                        borderColor: '#cccccc',
                        boxShadow: 'none',
                        width:'12vw'

                    }),
                    option: (provided, state) => ({
                        ...provided,
                        color: 'black'
                    })
                }}
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: Month.ID, label: Month.Label }}
                onChange={(e) => {
                    e ?
                        setMonth({ ...Month, ID: e.value, Label: e.label })
                        :
                        setMonth({ ...Month, ID: 0, Label: "Select Month..." })

                }}
                options={Month.DDL}
            />

        </div>
    )
}