import Header from "../../../Components/Header/Header"
import Sidebar from "../../../Components/Sidebar/Sidebar"
import GardenDailyWorkCardManage from "./GarderDashboardComponent/GardenDailyWork/GardenDailyWorkManage"
import GardenMaintainanceCardManage from "./GarderDashboardComponent/GardenMaintainanceCard/GardenMaintainanceCardManage"
import GardenMasterCardManage from "./GarderDashboardComponent/GardenMasterCard/GardenMasterCardManage"
import GardenReopenCardManage from "./GarderDashboardComponent/GardenReopenCard/GardenReopenCardManage"
import GardenWorkAssignManage from "./GarderDashboardComponent/GardenWorkAssign/GardenWorkAssignManage"
import { Pie, Bar, Doughnut } from 'react-chartjs-2';
import { Dcoptions, Pieoptions, WorkAssign } from "../../../Helper/Data"
import React, { useEffect, useState } from 'react'
import { useAuthState } from "../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { GardenDashMainCountDataAPI } from "../../../Redux/MainCountSlice/GardenMainCount"
import { WardNameDataDDL } from "../../../Components/CommonDDL/WardNameDataDDL"
import { Loading } from "../../../Helper/Loading"
import WorkProgress from "./GarderDashboardComponent/GardenWorkAssign/WorkProgress"
import { useSearchParams } from "react-router-dom"
import { BaseUrl } from "../../../Helper/BaseUrl"
import GardenMaintainanceComp from "./GarderDashboardComponent/GardenMaintainanceCard/GardenMaintainanceComp"
import CommonDashboardWardNameDDL from "./CommonDashboardWardNameDDL"
import { useTranslation } from "react-i18next"
import { EmployeeAndContractorWisePendingWorkCard } from "./GarderDashboardComponent/EmployeeAndContractorWiseWorkPending/EmployeeAndContractorWiseWorkPendingCard"

export const GardenDashboard = () => {

    const { t } = useTranslation()

    // localStorage.setItem("LanguageChange", 'English')

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")


    const Receiveddata = {
        labels: ["Completed", "Pending"],
        datasets: [
            {
                label: '# of Votes',
                data: [80, 20],
                backgroundColor: [
                    "#ffc33d",
                    "#f48242"
                ],
                borderColor: [
                    // '#28c8c1',
                    // '#172b4d',
                    // '#993366',
                    // '#593e89',
                    // '#94408e',
                    // '#c74382',
                    // '#fa5d00',
                    // '#529ad2',
                    // '#ffc137',
                ],
                borderWidth: 1,
            },
        ],
    };

    const Targetdata = {

        labels: ['DMC ', 'GO/AGO', 'GA', 'GO'],
        datasets: [
            {
                label: "Pending",
                fill: true,
                backgroundColor: [
                    "#cc2836",
                    "#cc2836",
                    "#cc2836",
                    "#cc2836"
                ],
                height: [
                    "10em"
                ],
                data: [2, 3, 1, 4]
            },
            {
                label: "Completed",
                fill: true,
                backgroundColor: [
                    "#FFB300",
                    "#FFB300",
                    "#FFB300",
                    "#FFB300"
                ],
                data: [5, 1, 2, 2]
            },
            {
                label: "Reopen Pending",
                fill: true,
                backgroundColor: [
                    "#253150",
                    "#253150",
                    "#253150",
                    "#253150"
                ],
                data: [4, 5, 6, 3]
            }

        ],
    };

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select Ward",
    })

    const [Countdata, setCountdata] = useState({})
    // const [FinancialYearID, setFinancialYear] = useState('')
    const [HeaderFinancialYear, setHeaderFinancialYear] = useState('')
    const [HeaderMonthID, setHeaderMonthID] = useState('')
    const [MonthID, setMonthID] = useState('')
    const [YearValue, setYearValue] = useState(null)
    const [YearLabel, setYearLabel] = useState(null)
    const [MonthValue, setMonthValue] = useState(null)
    const [MonthLabel, setMonthLabel] = useState(null)
    const [WardValue, setWardValue] = useState(null)
    const [WardLabel, setWardLabel] = useState(null)
    const [ZoneValue, setZoneValue] = useState(null)
    const [ZoneLabel, setZoneLabel] = useState(null)

    // const [searchParams] = useSearchParams()


    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    // console.log('first',ZoneLabel,ZoneValue)

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: '',
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])



    // console.log("WardValue", WardLabel)

    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleGetFinancialYearID = (FinancialYear, Month) => {
        setHeaderMonthID(Month)
        setHeaderFinancialYear(FinancialYear)
    }

    // console.log(HeaderFinancialYear)

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_WardID: WardName.ID,
            M_ZoneID: ZoneValue,
            M_MonthID: MonthValue,
            M_UserID: UserID,
            Flag: "WEB",
            token: token,
            handleGetCount: handleGetCount
        }
        // if (YearValue && ZoneValue) {
            dispatch(GardenDashMainCountDataAPI({ data }))
        // }
    }, [YearValue, MonthValue, WardName.ID, ZoneValue])

    const { GardenDashCountData, isLoading } = useSelector(state => state.GardenDashMainCountData)

    const handleGetCount = (data) => {
        // console.log("data", data)
        let tempData = {}
        data.forEach((item) => {
            tempData[item.paramName] = item.totalCount
        })
        setCountdata(tempData)
    }
    // console.log("vvfvfvvfvf", Language.Label)
    return (
        <>
            {isLoading && <Loading />}
            {/* <div className="main-content side_shrink position-relative border-radius-lg "></div> */}
            <div>
                <Header
                    // DashboardHeading="Garden Maintenance"
                    setYearValue={setYearValue}
                    setMonthValue={setMonthValue}
                    FinancialYear={FinancialYear}
                    setFinancialYear={setFinancialYear}
                    Month={Month}
                    setMonth={setMonth}
                    setYearLabel={setYearLabel}
                    setMonthLabel={setMonthLabel}
                    setWardValue={setWardValue}
                    setWardLabel={setWardLabel}
                    // setZoneValue={setZoneValue}
                    // setZoneLabel={setZoneLabel}
                    DashboardName={DashboardName}
                    HideYearMonthDDL='Dashboard'
                    Language={Language}
                    setLanguage={setLanguage}
                />
                {/* <Sidebar active="dashboard" /> */}
                <div className="wrapper">
                    <Sidebar
                        ProjectTypeId={ProjectTypeId}
                        Language={Language}
                    />
                    <div className="content1 content">

                        {/* <div id="page-content-wrapper"> */}
                        {/* <div className="pcoded-main-container main-row">
                        <div className="pcoded-content "> */}
                        {/* <!-- [ breadcrumb ] start --> */}
                        <CommonDashboardWardNameDDL
                            setWardValue={setWardValue}
                            setWardLabel={setWardLabel}
                            setZoneValue={setZoneValue}
                            setZoneLabel={setZoneLabel}
                            Language={Language}
                            ZoneNameDDLID={ZoneNameDDLID}
                            setZoneNameDDLID={setZoneNameDDLID}
                            WardName={WardName}
                            setWardName={setWardName}
                            Countdata={Countdata}
                        />
                        {/* <!-- [ Main Content ] start --> <!--  --> */}
                        {/* <GardenMaintainanceComp /> */}
                        <div className="m-1 py-2 pb-4 master_border">
                            <GardenMasterCardManage
                                Countdata={Countdata}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ZoneValue={ZoneValue}
                                ZoneLabel={ZoneLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName}
                                Language={Language}
                            />
                        </div>
                        
                        <div className="row mt-4 m-1 py-2 pb-0 master_border">
                            <GardenDailyWorkCardManage
                                Countdata={Countdata}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ZoneValue={ZoneValue}
                                ZoneLabel={ZoneLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName}
                                Language={Language}
                            />
                            <div className="col-12 col-lg-5 ">
                                <div className="row ">
                                    <div className="col-12 ">
                                        <div className=" px-3 pb-0 upload_chart bg-white ">
                                            <div className="row d-flex justify-content-center align-items-center ">
                                                <Pie
                                                    height={100}
                                                    // width={100}
                                                    options={Pieoptions}
                                                    data={Receiveddata}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4 m-1 py-2 pb-2 master_border d-flex align-items-center">
                            <GardenWorkAssignManage
                                HeaderFinancialYear={HeaderFinancialYear}
                                HeaderMonthID={HeaderMonthID}
                                Countdata={Countdata}
                                handleGetFinancialYearID={handleGetFinancialYearID}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ZoneValue={ZoneValue}
                                ZoneLabel={ZoneLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName}
                                Language={Language}
                            />
                            <div className="col-12 col-lg-5 " style={{ height: '100%' }}>
                                <div className="row py-2">
                                    <div className="col-12 " >
                                        <div className=" px-5 pt-4 upload_chart bg-white ">
                                            <div className="row d-flex justify-content-center align-items-center ">
                                                <Bar
                                                    options={WorkAssign}
                                                    data={Targetdata}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row mt-4 m-1 py-2 pb-2 master_border">
                            <WorkProgress
                                HeaderFinancialYear={HeaderFinancialYear}
                                HeaderMonthID={HeaderMonthID}
                                Countdata={Countdata}
                                handleGetFinancialYearID={handleGetFinancialYearID}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ZoneValue={ZoneValue}
                                ZoneLabel={ZoneLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName}
                                Language={Language}
                            />

                            <div className="col-12 col-lg-5 ">
                                <div className="row ">
                                    <div className="col-12 ">
                                        <div className=" px-3 pb-0 upload_chart bg-white ">
                                            <div className="row d-flex justify-content-center align-items-center ">
                                                <Pie
                                                    height={100}
                                                    // width={100}
                                                    options={Pieoptions}
                                                    data={Receiveddata}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="row mt-4 m-1 master_border last_row">

                            <GardenReopenCardManage
                                HeaderFinancialYear={HeaderFinancialYear}
                                HeaderMonthID={HeaderMonthID}
                                Countdata={Countdata}
                                handleGetFinancialYearID={handleGetFinancialYearID}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ZoneValue={ZoneValue}
                                ZoneLabel={ZoneLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName}
                                Language={Language}
                            />

                            <div className="col-12 col-lg-5 ">
                                <div className="row ">
                                    <div className="col-12 ">
                                        <div className="px-3 bg-white pt-5 pb-2">
                                            <span className="text-center float-md-right">
                                                <span className="text-black" style={{ fontSize: "14px" }}> <small>......</small> Pending </span>
                                                <span className="text-black" style={{ fontSize: "14px" }}> <small className="small2">......</small> Completed
                                                </span>
                                            </span>
                                            <div className="sc-gauge pb-4" id="gauge">
                                                <div className="sc-background">
                                                    <div className="sc-percentage">100%</div>
                                                    <div className="sc-mask"></div>
                                                    <span className="sc-value" style={{ fontSize: "25px" }}>70%</span>
                                                </div>
                                                <div>
                                                    {/* <Doughnut
                                            data={data}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: false
                                                    },
                                                    tooltip: {
                                                        enabled: false
                                                    }
                                                },
                                                rotation: -90,
                                                circumference: 180,
                                                cutout: "60%",
                                                maintainAspectRatio: true,
                                                responsive: true
                                            }}
                                        /> */}
                                                    {/* <div
                                            style={{
                                                position: "absolute",
                                                top: "55%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                textAlign: "center"
                                            }}
                                        >
                                            <div>Text Here</div>
                                        </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {/* <!--  --> */}




                            </div>
                            {/* </div> */}
                            {/* </div> */}
                        </div>

                        {/* <div className="row mt-4 m-1 master_border last_row">
                            <EmployeeAndContractorWisePendingWorkCard />
                        </div> */}


                    </div>
                    {/* <!-- [ Main Content ] end --> */}
                </div>
            </div>
        </>


    )
}