import React, { useEffect, useState } from 'react'
import { WardnameHeaderDDL } from '../../../Components/CommonDDL/WardnameHeaderDDL'
import { useTranslation } from 'react-i18next'
import { ZoneNameDDL } from '../../../Components/CommonDDL/DashboardDDL/ZoneNameDDL'
import { MonthHeaderDDL } from '../../../Components/CommonDDL/MonthHeaderDDL'
import { FinancialYearHeaderDDL } from '../../../Components/CommonDDL/FinancialYearHeaderDDL'

export default function CommonDashboardWardNameDDL(props) {

    const { t } = useTranslation()

    const { flag, setYearValue, setMonthValue, setMonthLabel, setYearLabel, setZoneValue, setZoneLabel, ZLable, ZWard, WLable, HWard, HYear, YLable, HMonth, MLable, WardName, setWardName, setWardValue, setWardLabel, Language, ZoneDDL, WardDDL, Countdata, handleGetFinancialYearID, Flag } = props
    // const [WardName, setWardName] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select Ward",
    // })
    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select Ward",
    })

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    useEffect(() => {
        handleGetFinancialYearID && handleGetFinancialYearID(FinancialYear, Month)
    }, [FinancialYear.ID, Month.ID])

    useEffect(() => {
        if (setYearValue !== undefined) {
            setYearValue(FinancialYear.ID)
        }
        if (setYearLabel !== undefined) {
            setYearLabel(FinancialYear.Label)
        }
    }, [FinancialYear.ID])

    useEffect(() => {
        if (setMonthValue !== undefined) {
            setMonthValue(Month.ID)
        }
        if (setMonthLabel !== undefined) {
            setMonthLabel(Month.Label)
        }
    }, [Month.ID])

    useEffect(() => {
        localStorage.setItem("ZoneNameDDLID", ZoneNameDDLID.ID)
    }, [ZoneNameDDLID])

    useEffect(() => {
        if (setWardValue !== undefined) {
            setWardValue(WardName.ID)
        }
        if (setWardLabel !== undefined) {
            setWardLabel(WardName.Label)
        }
    }, [WardName.ID])

    useEffect(() => {
        // console.log(WardName)
        if (setZoneValue !== undefined) {
            setZoneValue(ZoneNameDDLID.ID)
        }
        if (setZoneLabel !== undefined) {
            setZoneLabel(ZoneNameDDLID.Label)
        }
    }, [ZoneNameDDLID.ID])

    return (
        <>
            <div className="page-header pb-0">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12 ps-0">
                            <div className="page-header-title d-flex justify-content-between align-items-center">
                                {
                                    Flag == 'officer' ?
                                        <>
                                            <div className="col-12 col-lg-2 ps-4 ">
                                                <FinancialYearHeaderDDL
                                                    FinancialYear={FinancialYear}
                                                    setFinancialYear={setFinancialYear}
                                                    YLable={YLable}
                                                    HYear={HYear}
                                                    flag={flag}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-2 px-0">
                                                <MonthHeaderDDL
                                                    Month={Month}
                                                    setMonth={setMonth}
                                                    MLable={MLable}
                                                    HMonth={HMonth}
                                                    flag={flag}
                                                />
                                            </div>
                                        </>
                                        :
                                        <div className='col-5 col-md-6 col-lg-3'>
                                            <p className="text-dark ps-3 mb-0" style={{ fontSize: '20px' }}>{t('Dashboard.Titles.Master_Data')}</p>
                                        </div>
                                }


                                <div className="col-md-6 col-lg-8 col-7" style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                    <span className="pe-5 mt-2 fw-bold Total_count text-dark">
                                        Total Gardens : <span className="fw-bold Total_count">{Countdata.GardenTotalAllCount}</span>
                                    </span>

                                    <ZoneNameDDL
                                        ZoneNameDDLID={ZoneNameDDLID}
                                        setZoneNameDDLID={setZoneNameDDLID}
                                        Language={Language}
                                        ZLable={ZLable}
                                        ZWard={ZWard}
                                        ZoneDDL='DashboardZone'
                                    />

                                    <WardnameHeaderDDL
                                        WardName={WardName}
                                        setWardName={setWardName}
                                        WLable={WLable}
                                        HWard={HWard}
                                        flag={flag}
                                        Language={Language}
                                        M_ZoneID={ZoneNameDDLID.ID}
                                        WardDDL='DashboardWard'
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
